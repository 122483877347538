import React from 'react';
import { Row, Col, Card, Rate, Pagination,  Avatar, Input, Spin, Popover } from 'antd';
import moment from 'moment';
import { fire } from '../../../fire';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import firebase from 'firebase';

const DEFAULT_STATE = {
    error: null,
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    email: '',
    phone: '',
    name: '',

};

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            DEFAULT_STATE: DEFAULT_STATE,
            paying: false,
            success: false,
            plan: this.props.plan,
            loading: true,
            user: this.props.user,
            accountInfo: this.props.accountInfo,
            canceling: false,
            changing: false,
            userInfo: this.props.userInfo
        };
    }

    componentDidUpdate(prevProps) {



        if (this.props.accountInfo !== prevProps.accountInfo) {

            this.setState({ accountInfo: this.props.accountInfo, loading: false });
        }

    }

    componentDidMount() {

        



        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
           
            this.setState({ user, email: user.email, name: user.displayName });
            window.scrollTo(0, 0);
            if (this.state.plan) {
                
                this.setState({ loading: false })
            }

        });
    }


   



    reset = () => {
        this.setState(DEFAULT_STATE);
    };

    render() {
        const { error, processing, paymentMethod, name, email, phone, paying, success, canceling, accountInfo, userInfo } = this.state;
        const { stripe } = this.props;
      

        const CancelSubscriptionButton = () => {
            const history = useHistory();

            return (
                <Button  variant="contained" color="secondary" size="large" style={{borderRadius: 8}}
                 onClick={async (event) => {
                    event.preventDefault();
                    this.setState({ canceling: true })

                    const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const cancleURL = "https://us-central1-doprofile-fe8ca.cloudfunctions.net/test/cancelSubscription";
        const cancel = await fetch( cancleURL, {
            method: 'POST',
            body: JSON.stringify({
                subscription: accountInfo.subscription.id,
            }),
        });

        const cancelSubscription = await cancel.json();
        cancelSubscription.body = JSON.parse(cancelSubscription.body);


        const db = fire.firestore();
        var user = firebase.auth().currentUser;
        
        fire.auth().signOut().then(() => {
            history.push("/")

            user.delete().then(() => {
                
                    
                    fire.auth().signOut();
                    db.collection("users")
                    .doc(userInfo.id)
                    .delete()
                    .then(() => {
                        
                        this.props.signedOut(false);
                    })
            
              
                   
                
            }).catch(function(error) {
              // An error happened.
            })

           
        })
        

       

      
      
        


                 }}>
                     Yes
                     </Button>
            )


        }



        return (

            <Row gutter={[32, 8]}>
               

               


                <Col xs={24} sm={24} md={6} style={{ display: canceling ? 'none' : null }}>
                    
                <Popover  content={(<div style={{textAlign: 'center', fontSize: 18, padding: 12}}>
                    Are you sure you want to cancel your subscription?
                    <br />
                    <br />
                    
                    <CancelSubscriptionButton />

                </div>)} trigger="click">
                   

                <Button fullWidth variant="contained" color="secondary" size="large" style={{borderRadius: 8}} >Cancel</Button>
                        
                   
                </Popover>
                </Col>

                <Col xs={24} sm={24} md={6}style={{ display: !canceling ? 'none' : null }}>
                    Canceling Subscription  <Spin />
                </Col>
            </Row>






        );
    }
}

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} accountInfo={props.accountInfo} userInfo={props.userInfo} user={props.user} signedOut={props.signedOut} />
        )}
    </ElementsConsumer>
);



export default class AccountSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountInfo: {},
            user: this.props.user,
            userInfo: this.props.userInfo,
            stripePromise: loadStripe("pk_live_51IP9lNHU6eb6yd8udT2j7ayZaeIFN0QhtHxO3maaenbLgftUhkHEEEmmazHeDpUYO2XYlgm4CUmdsBElhcBxiX8V000635kMpK"),
          
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountInfo !== prevProps.accountInfo) {

            this.setState({ accountInfo: this.props.accountInfo });


        }
    }

    componentDidMount() {

        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            window.scrollTo(0, 0);
           
            this.setState({ userInfo: user });

            const db = fire.firestore();
            db.collection("users")
            .doc(user.uid)
            .collection("accountInfo")
            .onSnapshot((snapshot) => {
                const accountInfoList = [];
                snapshot.forEach((doc) => {
                    accountInfoList.push({ ...doc.data() })
                })
                const accountInfo = {};
                accountInfoList.forEach((a) => {
                    accountInfo[a.label] = a;
                })
               
                this.setState({ accountInfo })
            })

        });
       

    }



    render() {


        const InputHeader = (props) => {
            return (

                <Row gutter={[32,]} align="middle" >
                    <Col span={12} style={{ fontSize: 16 }}>
                        <b>{props.name}</b>
                    </Col>

                </Row>

            )

        }


        const { accountInfo, userInfo  } = this.state;

        if (accountInfo.subscription !== undefined)
        {
            return (
                <div>
                    <Card style={{ boxShadow: '3px 3px 8px #888888', fontFamily: 'Roboto, sans serif' }}>
                        <Row gutter={[32, 32]}  >
                            <Col xs={24} sm={16} style={{ fontSize: 28 }}>
                                Account Summary
                          </Col>
                         
                        </Row>
                      
    
                            <Row gutter={[32, 16]} >
                                <Col span={24} style={{ fontSize: 20 }}>
                                    Current Plan: 
                                   
                                    $6.50 per month
                        </Col>
                       
                                <Col span={24} style={{fontSize: 18}}>
                                    Plan Started: {accountInfo ? moment(accountInfo.subscription.created, 'X').format('MM-DD-YYYY') : null}
                                </Col>
                                <Col span={24} style={{fontSize: 18}}>
                                    Period Start: {accountInfo ? moment(accountInfo.subscription.current_period_start, 'X').format('MM-DD-YYYY') : null}
                                </Col>
                                <Col span={24} style={{fontSize: 18}}>
                                    Period End: {accountInfo ? moment(accountInfo.subscription.current_period_end, 'X').format('MM-DD-YYYY') : null}
                                </Col>
    
                                <Col span={24} style={{fontSize: 18}}>
                                    <Elements stripe={this.state.stripePromise}>
                                        <InjectedCheckoutForm accountInfo={accountInfo} user={this.state.user} userInfo={userInfo} signedOut={this.props.signedOut} />
                                    </Elements>
    
                                </Col>
                            </Row>
                            
    
    
    
    
    
                    </Card>
                </div>
            )  
        }
        else {
            return null;
        }
        
    }

}