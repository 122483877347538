import HowToVoteIcon from '@material-ui/icons/HowToVote';
import Mail from '@material-ui/icons/Mail';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Col, Row, Card, Grid, Tag,  } from 'antd';
import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';


const { useBreakpoint } = Grid;

 export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenSize: ["lg", true],
            info: this.props.info
          };
      }



      updateScreen = (size) => {
          this.setState({screenSize: size})
          
      }

      

      render () {
        const {screenSize} = this.state;
        const Line = () => {
            return (
                <Row>
                    <Col span={24}>
                        <hr style={{
                            color: '#92C2E6',
                            backgroundColor: '#92C2E6',
                            height: 1.5
                        }} />
                    </Col>
                </Row>
            )
        }

        

        const PlanItem = (props) => {
            
            return (
                <Col xs={24} sm={12} >
                    <Box boxShadow={8} style={{borderRadius: 12}}>
                    <div style={{position: 'relative', top: 0, width: '100%', backgroundColor: props.bandColor, 
                    height: 15, zIndex: 3, borderRadius: '12px 12px 0px 0px'}} />
                    <Card style={{borderRadius: 12}} >
                    
                <Row justify="space-around" gutter={[8,8]} >
                    
                
                    
                    <Col span={24} style={{textAlign: 'center', fontSize: 32, fontFamily: 'Roboto, sans-serif',  }}>
                        <b>Pro Plan</b>
                    </Col>
                    <Col span={24}>
                        <Line />
                    </Col>
                    
                    <Col span={24} style={{textAlign: 'center', fontSize: 55, fontFamily: 'Roboto, sans-serif', color: "#3f51b5" }}>
                        <b>${props.price}</b><span style={{fontSize: 14}}>/month</span>
                    </Col>
                    <Col span={24}>
                        <Line />
                    </Col>
                    <Col span={24} style={{textAlign: 'left', fontSize: 16, fontFamily: 'Roboto, sans-serif', color: "rgba(0,0,0,.8)" }}>
                        {props.planItems.map((p) => {
                            return (
                                <p>• {p}</p>
                            )
                        })}
                    </Col>
                    <Col span={24}>
                   
                   
                        <Button  variant="contained" fullWidth color="primary" size="large" style={{fontSize: 18, fontFamily: 'Roboto, sans-serif', borderRadius: 4, textTransform: 'initial'}}
                        onClick={() => {
                            this.props.updatePlan(props.tier, props.price)
                        }}>
                            Sign Up
                        </Button>
                        
                    </Col>
                   
                  
                </Row>
                </Card>
                </Box>

                </Col>
            )
        }

        const  UseBreakpointDemo = () => {
            const screens = useBreakpoint();

            const screenItems = Object.entries(screens);
            const filterScreens = screenItems.filter(screen => !!screen[1]);
           
            const filterLength = filterScreens.length;
            const size = filterScreens[filterLength-1];
            
            if (size !== undefined && this.state.screenSize !== undefined && size[0] !== this.state.screenSize[0] )
            {
              
              
               this.updateScreen(size);
            }
           

            return (
              null
            )
        } 
 

          return (
              <div style={{minHeight: '100vh', paddingTop: screenSize[0] === "xs" ? 45 : null}}>
            <Row justify="space-around" gutter={[32,8]} align="middle">
            <Col span={24} style={{textAlign: 'center', fontSize: 38, fontFamily: 'Roboto, sans-serif',  }}>
           Pick Your Plan
            </Col>
           
            <Col span={18} style={{textAlign: 'center', fontSize: 18, fontFamily: 'Roboto, sans-serif',  paddingTop: 8  }}>
           
            Explore which option is right for you.

            </Col>

           <Col span={24}>
           <Line />
           </Col>

          

            <Col span={24}>
            <Row justify="center" gutter={[16,32]}>

                
            <PlanItem priceLevel={"Basic"} tier={'2'} price={"6.50"}  cardHeight={"80%"} bandColor={"#7718ffad"}
            tierLevel={"30,001 - 300,000 Galons per year"}
            planItems={(["Save your profiles.", "Add reference lines to your profiles.", "Remove our logo from the profiles."])}
            />

         

          
           <UseBreakpointDemo />

            </Row>
            </Col>

            <Col span={24}>
           <Line />
           </Col>

           

            </Row>
            </div>
          )
      }

 }