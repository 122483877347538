import React from 'react';
import { Row, Col } from 'antd';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';

export default class ProfileDataDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            keys: []
        };
    }


    getKeys = (data, file) => {
       
        
        if (data !== undefined) {

            const filterData = [];
            data.forEach((d) => {
                if (d.data.length > 1) {
                    filterData.push((d.data))
                }
            })
            const newData = [];
            filterData.forEach((d) => {
                if (d[0] && d[1] && d[0].length > 0 && d[1].length > 0) {
                    newData.push(d);
                }
            })
            const keys = newData.shift();
        

            const updatedData = [];
            newData.forEach((d, i) => {

                const obj = {};
                keys.forEach((k, index) => {



                    obj[k] = d[index];


                })
                updatedData.push(obj);



            })


           

            this.props.updateStep("mapKeys")
            this.props.updateProfile("data", updatedData, )
            setTimeout(() => {
                this.props.updateProfile("keys", keys)
            }, 250);
           
            
            this.setState({ keys, data: updatedData,  })
        }
    }

    

    render() {
        const { userInfo, account, keys, data } = this.state;

        return (
            <Row gutter={[16, 16]} justify="center" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14, color: 'rgba(0,0,0,.7)', paddingTop: 20 }}>
                    <Col span={16} style={{height: '200px'}} >
                        <CSVReader
                            onDrop={this.getKeys}
                            onError={(err) => {
                              
                            }}

                        >
                            <span>Drop CSV file here to upload.</span>
                        </CSVReader>

                    </Col>
                </Row>
            
        )
    }

}