import React from 'react';
import { Row, Col } from 'antd';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import Capture2 from './images/Capture2.JPG';
import Capture3 from './images/Capture3.JPG';
import Capture1 from './images/Capture1.JPG';

export default class ProfileDataDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            data: [],
            keys: []
        };
    }


    getKeys = (data, file) => {
        console.log(file.type);
        if (file.type.includes("excel") || file.type.includes("spreadsheetml"))
        {
            if (data !== undefined) {

                const filterData = [];
                data.forEach((d) => {
                    if (d.data.length > 1) {
                        filterData.push((d.data))
                    }
                })
                const newData = [];
                filterData.forEach((d) => {
                    if (d[0] && d[1] && d[0].length > 0 && d[1].length > 0) {
                        newData.push(d);
                    }
                })
                const keys = newData.shift();
              
    
                const updatedData = [];
                newData.forEach((d, i) => {
    
                    const obj = {};
                    keys.forEach((k, index) => {
                        obj[k] = d[index];
    
    
                    })
                    updatedData.push(obj);
    
                })
                this.props.updateStep("mapKeys")
                this.props.updateProfile("data", updatedData, )
                setTimeout(() => {
                    this.props.updateProfile("keys", keys)
                }, 250);
                this.setState({ keys, data: updatedData,  })
            }
        }
        else {
            this.props.openSnack("error", "Wrong file type (accepts csv or xls).")
        }
       
    }

    

    render() {
        const { userInfo, account, keys, data } = this.state;
        const boxShadow = 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px';

        return (
           
                <Row gutter={[16, 16]} justify="center" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14, color: 'rgba(0,0,0,.7)', paddingTop: 20 }}>
                    <Col span={24} style={{height: '150px'}} >
                        <CSVReader
                        
                            onDrop={this.getKeys}
                            onError={(err) => {
                               
                            }}

                        >
                            <span>Drop CSV file here to upload.</span>
                        </CSVReader>

                    </Col>
                            
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                Add References
                            </Col>
                            <Col span={24}>
                            <img src={Capture3} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                         
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                See trends
                            </Col>
                            <Col span={24}>
                            <img src={Capture2} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                Export Image
                            </Col>
                            <Col span={24}>
                            <img src={Capture1} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                        
                    </Col>


                </Row>
         
        )
    }

}