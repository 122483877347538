import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { fire } from '../../fire';
import Routing from './externalRouting';
import SignedInRouting from './internalRouting';


export default class Router extends PureComponent {
    constructor(props) {
        super(props); 
        this.state = {
            signedIn: false
        };
    }

    signedOut = (status) => {
        this.setState({ signedIn: status })
    }

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ signedIn: true })
            }
        });
    }

    render() {

        const { signedIn } = this.state;

        if (signedIn) {
            return (
                <BrowserRouter style={{ minHeight: '100vh' }}>
                    <SignedInRouting signedOut={this.signedOut} />
                </BrowserRouter>
            )
        }
        else {
            return (
                <BrowserRouter style={{ minHeight: '100vh' }}>
                    <Routing />
                </BrowserRouter>
            )
        };



    }

}