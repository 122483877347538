import React from 'react';
import { Row, Col, Card, Rate, Pagination, Button, Avatar, Input, Table, Tag } from 'antd';
import { fire } from '../../../fire';import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

 export default class AccountPurchased extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountInfo: this.props.accountInfo,
            user: this.props.user,
            purchaseHistory: [],
          };
      }

      componentDidUpdate(prevProps) {
        if (this.props.accountInfo !== prevProps.accountInfo) {

            this.setState({ accountInfo: this.props.accountInfo });

            
        }
    }

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            const db = fire.firestore();

            window.scrollTo(0, 0);
            db.collection(`users`)
            .doc(user.uid)
            .collection("purchaseHistory")
            .onSnapshot((snapshot) => {
                const purchaseHistory = [];
                snapshot.forEach((doc) => {
                    purchaseHistory.push({ ...doc.data() })
                })
               
                this.setState({ purchaseHistory, user })
            });
        });
    }

      render () {

        const ViewPuzzle = (props) => {
            let history = useHistory();
            return (
                <div  

            onClick={() => {
                this.removeAuthListener = fire.auth().onAuthStateChanged(user => {

                    if (user)
                    {
                        const db = fire.firestore();
                       
                        db.collection("users").doc(user.uid)
                            .update({ currentPuzzle: props.puzzle.key })
                            .then(()=> {
                              
                                history.push("/puzzle");
                            })
                           
                    }
                   
               


                });
            }} >

            <img src={props.puzzle.imgLink} height={'45px'} /></div>
            )
        }

        const columns = [
            {
                title: 'Thumb',
                dataIndex: ["puzzle", "imgLink"],
                width: '100px',
                key: 'imgLink',
                render: (imgLink, row) => {
                    const key = row.puzzle.key;
                    return (
                      
                       <ViewPuzzle puzzle={row.puzzle} />
                    )
                }
            },
            {
                title: 'Name',
                dataIndex: ['puzzle','name'],
                key: 'name',

            },
            {
                title: 'Pieces',
                dataIndex: ['puzzle','pieces'],

            },
          
            
            {
                title: 'Date',
                dataIndex: 'timeStamp',
                render: (t) => {
                    return (moment(t, 'X').format('MM-DD-YYYY'))
                }

            },

           
            
        ];


       


        const { purchaseHistory, user  } = this.state;
          return (
              <div>
                  <Card style={{boxShadow: '3px 3px 8px #888888',}}>
                  <Row gutter={[32, 32]} justify="space-around" >
                      <Col span={24} style={{ fontSize: 24 }}>
                          Purchase History
                      </Col>
                      <Col span={24} style={{ fontSize: 18 }}>
                          Purchases
                      </Col> 
                  </Row>

                 
                      
                      <Row gutter={[32,]} align="middle" style={{ paddingTop: 20 }}>


                      <Col span={24} style={{ fontSize: 36 }}>
                          <Table columns={columns} dataSource={purchaseHistory}
                          />
  
                      </Col>
  
                  </Row>

                 
                  </Card>
              </div>
          )
      }

 }