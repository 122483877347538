import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize:20,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function FAQpage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>What are some popular uses for AquaSource?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We work with lake managers, HOAs, lake management companies, and many more to help them keep all their water quality in a central location for their entire team to access.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Where is my data stored?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your data is stored in a secure cloud database for you to access whenever you have an internet or wifi connection.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>How much does it cost?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This depends on a few factors including how many water bodies your are looking to manage and how much data you will be uploading.  Please feel free to reach out to Greg@AquaSourceManagement.com for a complete pricing breakdown
            for your account.
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}