import { Button, TextField } from '@material-ui/core';
import { Col, message, Row, Spin } from 'antd';
import firebase from 'firebase';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { fire } from '../../../fire';

// Configure Firebase.

   
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/home',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      
      
    ] 
  };

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          checking: false,
          info: {email: "", password: ""},
          badInputs: [],
          widthSize: this.props.widthSize
         };
      }

      componentDidUpdate(prevProps) {
        if (this.props.widthSize !== prevProps.widthSize) {
            this.setState({ widthSize: this.props.widthSize });
        }
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

      checkingCreds = () => {
        this.setState({checking: true})
      }
      checkedCreds = () => {
        this.setState({checking: false})
      }

      render () {


        const JoinButton = (props) => {
          const history = useHistory();
  
          return (
            <Button variant="contained" fullWidth color="primary" 
            style={{fontFamily: 'Roboto, sans-serif', fontSize: 14, borderRadius: 6,
            textTransform: 'capitalize',}} fullWidth 
            onClick={() => {
              this.checkingCreds();
             
              const email = props.info.email || "";
              const p = props.info.password || "";
             
              fire.auth().signInWithEmailAndPassword(email, p).then((result) => {
                this.checkedCreds();
                history.push('/')
            }).catch((error) => {
                // Handle Errors here.
             
                this.checkedCreds();
                message.error("Not a valid email or password");
                // ...
            });
                
                
  
  
  
              
              
  
            }}> 
              Sign In
            </Button>
          )
         
        }


        const TryDemoButton = () => {
          const history = useHistory();

          return (
            <Button variant="outlined" fullWidth color="primary" 
            style={{fontFamily: 'Roboto, sans-serif', fontSize: 14, borderRadius: 6,
            textTransform: 'capitalize',}}   fullWidth
            onClick={() => {
             history.push("/demo")
                
  
            }}> 
              Try Demo
            </Button>
          )
        }

        const { checking, info, badInputs, widthSize } = this.state;

          return (
            <div style={{ paddingTop: widthSize === "xs" ? 80 : null}}>
{checking ? 
                 <Row justify="space-around" gutter={[32,8]} align="middle" style={{ paddingTop: 18 }}>
                 <Col span={24} style={{textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif',  }}>
                 Logging In...
                 </Col>
                 <Col span={24} style={{textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif',  }}>
                 <Spin size="large" />
                 </Col>
                 </Row>
                 
                 :
                 <Row justify="space-around" gutter={[32,8]} align="middle" style={{ paddingTop: 18 }}>
                  <Col span={24} style={{textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif',  }}>
                  Log In
                  </Col>
                
               

              <Col xs={20} sm={14}  style={{paddingTop: 15}}>
                <Row  justify="space-around" gutter={[8,16]} align="middle" >
               


              
             
              <Col xs={24} sm={12}  style={{textAlign: 'right'}}>

              <TextField id="outlined-basic" label="Email" fullWidth variant="outlined"
                    style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                    value={info.email}
                    placeholder={"Email"}
                    key={"email"}
                    onChange={(e) => {
                      const info = this.state.info;
                      info.email = e.target.value;
                      this.setState({ info })
                    }}
                    error={badInputs.includes("email")} />
              </Col>

              <Col xs={24} sm={12}  style={{textAlign: 'right'}}>
               <TextField id="outlined-basic" label="Password" fullWidth variant="outlined" type="password"
                    style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                    value={info.password}
                    placeholder={"Password"}
                    key={"password"}
                    onChange={(e) => {
                      const info = this.state.info;
                      info.password = e.target.value;
                      this.setState({ info })
                    }}
                    error={badInputs.includes("password")} />
              </Col>
            


             


              <Col span={24} style={{ }}>
                
              </Col>

              <Col span={24} style={{textAlign: 'center', fontSize: 14, fontFamily: 'Roboto, sans-serif',  }}>
              <JoinButton info={info} />
              </Col>

              

              <Col span={24} style={{textAlign: 'center', fontSize: 14, fontFamily: 'Roboto, sans-serif',  }}>
                  <Button block
                                type="link" size="large"
                                onClick={() => {
                                    var auth = fire.auth();
                                    if (info.email !== "")
                                    {
                                      var emailAddress = info.email;

                                    auth.sendPasswordResetEmail(emailAddress).then(function () {
                                      message.success("Password reset link has been emailed to you.");
                                    }).catch(function (error) {
                                     
                                    });
                                    }
                                    else {
                                      message.error("Please enter email address first.");
                                    }
                                    
                                   
                                }}>
                                Forgot Password?
                          </Button>
                  </Col>


               

              </Row>
              </Col>

              
                 

              



                
                 
              </Row>
                 }
             
              </div>
            
          )
      }

 }