import React from 'react';
import { Row, Col, Collapse } from 'antd';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Dashboard from './images/dashboard.JPG';
import TableImg from './images/labTable.JPG';
import Profiles from './images/profiles.JPG';
import Discovery from './animations/Discovery Animation/Discovery Colour/Illustration/discovery.svg';
import FAQ from './animations/SEO Colour/seo.svg';
import Analytic from './animations/Analytic/Analytic.svg';
import FAQpage from './faq';
import Capture2 from './images/Capture2.JPG';
import Capture3 from './images/Capture3.JPG';
import Capture1 from './images/Capture1.JPG';
const { Panel } = Collapse;

export default class LandingPage extends React.Component {
    constructor(props) { 
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            widthSize: this.props.widthSize
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
      }

    render() {
        const { userInfo, account, widthSize } = this.state;
        const boxShadow = 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px';

        return (
            <div style={{ minHeight: '100vh', fontFamily: 'Roboto, sans-serif', }}>

                <object type="image/svg+xml" data={Analytic} style={{ position: 'absolute', left: '70%', top: 120, height: widthSize === "xs" ? 0 : widthSize === "sm" ? 0 : 300, zIndex: 0 }}>svg-animation</object>

                <Row gutter={[16, 8]} style={{ fontSize: 12, color: 'rgba(0,0,0,.7)' }} align="middle" justify="center">






                    <Col xs={24} sm={17} md={17} style={{ textAlign: 'center', paddingTop: 50, fontSize: '60px' }}>
                        Build Dissolved Oxygen and Temperature Profiles.
                                </Col>
                    <Col xs={24} sm={15} style={{ textAlign: 'center', fontSize: '60px' }}>
                        <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/buildProfile">
                                <Button variant="contained" size="large" color="primary" style={{ textTransform: 'capitalize', fontFamily: 'Roboto, sans-serif', }} >
                                Build now
                                    </Button>
                        </Link>
                    </Col>


                </Row>
                <Row gutter={[16, 0]} style={{}} align="middle" justify="center">
                    <Col xs={24} sm={16} style={{ textAlign: 'center', paddingTop: 20, fontSize: '30px', color: '#2180c3' }}>
                        How it Works
                                </Col>
                    
                </Row>
                <Row gutter={[32, 24]} style={{}} align="middle" justify="sapce-around" style={{ color: 'rgba(0,0,0,.7)', fontSize: '26px', padding: 30 }}>



                    {[
                        ["Upload Data", "Upload a csv file with your sample points including depths, dissolved oxygen readings, temperature or any other paramters."],
                        ["Build Profile", "Choose your profile parameters and design settings to create your new depth profile."],
                        ["Export Profile", "Expport out your profile as a PNG and use it for any reports or other documents."]
                    ].map((s) => {

                        return (
                            <Col xs={24} sm={8} style={{ textAlign: 'center', paddingTop: 30, }}>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        {s[0]}
                                    </Col>
                                    <Col span={24} style={{ color: 'rgba(0,0,0,.9)', fontSize: '20px', textAlign: 'left', paddingTop: 8 }}>
                                        {s[1]}
                                    </Col>

                                </Row>

                            </Col>
                        )
                    })}



                </Row>




                <Row gutter={[16, 16]} align="middle" justify="center" style={{ paddingTop: 5 }}>
                   
                    <Col xs={24} sm={24} style={{ textAlign: 'center', paddingTop: 30, fontSize: '30px', color: '#2180c3' }}>
                        Build Profiles
                                </Col>
                    <Col xs={24} sm={24} style={{ textAlign: 'center', fontSize: '24px', color: 'rgba(0,0,0,.7)' }}>
                        Select which parameters you would like to see graphed.  Add references to your profile.
                                </Col>

                                <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                Add References
                            </Col>
                            <Col span={24}>
                            <img src={Capture3} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                         
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                See trends
                            </Col>
                            <Col span={24}>
                            <img src={Capture2} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <Row gutter={[16,16]}>
                            <Col span={24} style={{fontSize: 28}}>
                                Export Image
                            </Col>
                            <Col span={24}>
                            <img src={Capture1} style={{ maxWidth: '90%', boxShadow }} />
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                 
                

                
                <Row gutter={[16, 0]} style={{}} align="middle" justify="center" style={{ paddingBottom: 50 }}>
                    <Col xs={24} sm={16} style={{ textAlign: 'center', paddingTop: 30, fontSize: '30px', color: '#2180c3' }}>
                        Contact Us
                                </Col>
                    <Col xs={24} sm={16} style={{ textAlign: 'center', fontSize: '20px', color: 'rgba(0,0,0,.7)' }}>
                        Greg@DO-Profile.com
                                </Col>
                </Row>


            </div>
        )
    }

}