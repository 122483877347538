import React from 'react';
import firebase from 'firebase';
import { fire } from '../../../fire';
import { Row, Col, Input, Checkbox, message, Spin } from 'antd';
import { Link, useHistory, } from 'react-router-dom';
import { TextField, Button, } from '@material-ui/core';
import uuid from 'react-uuid';
import Pricing from '../pricing/pricing';
import SignUpForm from './signUpForm';
import Checkout from './checkout';

// Configure Firebase.


// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/home',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,

  ]
};

export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        password: "", firstName: "", lastName: "", email: "", displayName: "", password: "", confirmPassword: "", agreeTerms: false, company: "",
        creditsInUse: 0,
        plan: {},
        status: 'planPick'
      },
      checking: false,
      checkInputs: false,
      badInputs: [],
      widthSize: this.props.widthSize
      
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  checkingCreds = () => {
    this.setState({ checking: true })
  }
  checkedCreds = () => {
    this.setState({ checking: false })
  }

  checkInputs = () => {
    this.setState({ checkInputs: true })

    const badInputs = [];
    Object.values(this.state.info).map((info, i) => {
      if (info === "" || info === false) {
        badInputs.push(Object.keys(this.state.info)[i]);
      }
    });

    this.setState({ badInputs });
  }


  updatePlan = (tier, price) => {
    const { info } = this.state;

    info.plan.tier = tier;
    info.plan.price = price;
    info.status = "userInfo";
    this.setState({ info })
   
  }

  updateInfo = (info) => {
    this.setState({info})
   
  }


  render() {

    const { info, checking, badInputs, userInfo, widthSize } = this.state;

   




    if (info.status === "planPick") {
      return (
        <div>
        <Pricing info={info} updatePlan={this.updatePlan} widthSize={widthSize} />
        
        </div>
      )
    }
    if (info.status === "userInfo") {
      return (
        <Row justify="space-around">
        <Col span={16} style={{textAlign: 'center'}}>
        <SignUpForm updateInfo={this.updateInfo} info={info} openSnack={this.props.openSnack} widthSize={widthSize}  />
        </Col>   
        </Row>

      )
    }
    if (info.status === "payment") {
      return (
        <Row justify="space-around">
        <Col span={16} style={{textAlign: 'center'}}>
        <Checkout updateInfo={this.updateInfo} info={info} openSnack={this.props.openSnack} widthSize={widthSize}  />
        </Col>   
        </Row>

      )
    }


  }

}