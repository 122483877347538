import { MenuOutlined } from '@ant-design/icons';
import { Button, Fab, Slide, Snackbar } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import Storefront from '@material-ui/icons/Storefront';
import { Alert } from '@material-ui/lab';
import { Col, Grid, Layout, Row, Spin } from 'antd';
import React, { PureComponent } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';
import { fire } from '../../fire';
import TimelineIcon from '@material-ui/icons/Timeline';
import './menu.css';

import HomePage from '../internal/homePage';
import BuildProfileHome from '../internal/build/buildProfileHome';
import Account from '../internal/account/account';





const { Header, Content, Footer } = Layout;

const { useBreakpoint } = Grid;

export default class SignedInRouting extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            user: {},
            userInfo: {},
            accountKey: "",
            account: {},
            company: {},
            reroute: true,
            accountPage: 'email',
            widthSize: "lg",
            slideAccount: false,
            slideCompany: false,
            path: "",
            sites: [],
            open: false,
            snackOpen: false,
            snackStatus: "",
            snackDescription: "",
            plan: {},
            profile: {}
        };
    }



    showDrawer = () => {
        this.setState({
            visible: true,
            slideAccount: false,
            slideCompany: false

        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    updateCost = (orderCost) => {
        this.setState({
            orderCost
        })
    }
    signOut = () => {
        this.setState({
            visible: false,
        });
        fire.auth().signOut();
        this.props.signedOut(false);
    };


    updateScreen = (size) => {
        this.setState({ widthSize: size })

    }




    componentDidMount() {

        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            window.scrollTo(0, 0);
            
            this.setState({ userInfo: user });


        });

    }

    


    updateUserInfo = (key, action) => {
        const userInfo = this.state.userInfo;
        if (action === "add") {
            const accounts = userInfo.accounts;
            accounts.push(key); 
            userInfo.accounts = accounts;
            this.setState({ userInfo })
        }
        if (action === "remove") {
            const filterAccounts = userInfo.accounts.filter(a => a !== key);
            userInfo.accounts = filterAccounts;
            this.setState({ userInfo })
        }

    }


    updateAccountItem = (item, value) => {
        const { account } = this.state;
        account[item] = value;
        this.setState({ account })
    }

    



    closeTools = () => {
        this.setState({ slideAccount: false, slideCompany: false })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleCloseSnack = () => {
        this.setState({ snackOpen: false })
    }

    openSnack = (snackStatus, snackDescription) => {
        this.setState({ snackOpen: true, snackStatus, snackDescription })
    }

    updateProfile = (profile) => {
        this.setState({profile})
    }

    render() {

        const UseBreakpointDemo = () => {
            const screens = useBreakpoint();

            const screenItems = Object.entries(screens);
            const filterScreens = screenItems.filter(screen => !!screen[1]);

            const filterLength = filterScreens.length;
            const size = filterScreens[filterLength - 1];

            if (size !== undefined && this.state.widthSize !== undefined && size[0] !== this.state.widthSize) {

                this.updateScreen(size[0]);
            }


            return (
                null
            )
        }

        const Loading = (props) => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            Loading {props.page || "Page"}...
                 </Col>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <Spin size="large" />
                        </Col>
                    </Row>
                </div>
            )
        }

        const FindPath = () => {
            let location = useLocation();
            if (location.pathname !== this.state.path) {
                this.setState({ path: location.pathname })
            }
            return null;

        }




        const { user, loadScene, userInfo, company, snackOpen, snackDescription, snackStatus, profile,
            reroute, widthSize, account, slideAccount, slideCompany, plan,
            path } = this.state;

        return (

            <Layout>
                <Header style={{
                    zIndex: 3,
                    backgroundColor: '#1e5fb9e6', width: '100%', height: '100px', color: 'white', padding: '0 30px',
                    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                }}>
                    <Row style={{ paddingTop: 26, display: "flex", alignItems: "center", }} gutter={[8, 8]}>
                        <Col xs={4} sm={2} >
                            <Button style={{ color: 'white', }} size="large" onClick={this.showDrawer}>
                                <MenuOutlined style={{ fontSize: 26 }} />
                            </Button>
                        </Col>
                        



                        {widthSize !== "xs" && (
                            <Col xs={20} sm={22}  style={{ textAlign: 'right', color: 'white', fontFamily: 'Roboto, sans-serif', fontSize: 26 }}>
                                {user.displayName || ""}
                                <span>    <Link style={{ textDecoration: 'none', cursor: 'pointer', }} to="/account">


                                    <Fab aria-label="add" size="medium" style={{ backgroundColor: 'white' }}
                                    >
                                        <PersonIcon />
                                    </Fab>
                                </Link></span>

                            </Col>
                        )}

                    </Row>
                </Header>






                {reroute ? <Content style={{ backgroundColor: 'white' }}>
                    <Slide direction="down" in={slideAccount} mountOnEnter unmountOnExit>
                        <div style={{
                            position: 'absolute', zIndex: 2, top: '100px',
                            backgroundColor: 'white', width: '100%', padding: '0 30px',
                            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                        }}>
                          
                        </div>
                    </Slide>
                    <Slide direction="down" in={slideAccount} mountOnEnter unmountOnExit>
                        <div style={{
                            position: 'absolute', zIndex: 1, top: 0, width: '100%', height: '100%', backgroundColor: 'transparent'
                        }} onClick={() => this.setState({ slideAccount: false })}>

                        </div>
                    </Slide>
                    <Slide direction="down" in={slideCompany} mountOnEnter unmountOnExit>
                        <div style={{
                            position: 'absolute', zIndex: 2, top: '100px',
                            backgroundColor: 'white', width: '100%', padding: '0 30px',
                            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                        }}>
                            
                        </div>
                    </Slide>
                    <Slide direction="down" in={slideCompany} mountOnEnter unmountOnExit>
                        <div style={{
                            position: 'absolute', zIndex: 1, top: 0, width: '100%', height: '100%', backgroundColor: 'transparent'
                        }} onClick={() => this.setState({ slideCompany: false })}>

                        </div>
                    </Slide>

                    <Row style={{ paddingTop: 15 }}>
                        <Col offset={1} span={22}>







                            <Route exact path="/" render={() => {
                                setTimeout(() => {
                                    this.setState({ loadScene: true });
                                }, 1000);

                                return (
                                    loadScene ? <HomePage user={user} userInfo={userInfo} company={company} account={account} updateProfile={this.updateProfile} openSnack={this.openSnack} /> : <Loading page="Profiles" />
                                )
                            }} />
                            <Route exact path="/buildProfile" render={() => {
                                setTimeout(() => {
                                    this.setState({ loadScene: true });
                                }, 1000);

                                return (
                                    loadScene ? <BuildProfileHome user={user} userInfo={userInfo} profile={profile} company={company} account={account} updateAccount={this.updateAccount} openSnack={this.openSnack} /> : <Loading page="Profile" />
                                )
                            }} />
                            <Route exact path="/account" render={() => {
                                setTimeout(() => {
                                    this.setState({ loadScene: true });
                                }, 1000);

                                return (
                                    loadScene ? <Account user={user} userInfo={userInfo} profile={profile} company={company} account={account} updateAccount={this.updateAccount} openSnack={this.openSnack}  signedOut={this.props.signedOut}/> : <Loading page="Profile" />
                                )
                            }} />
                            











                        </Col>
                    </Row>

                    {/*  <Modal
                        footer={(null)}
                        visible={open}
                        onCancel={this.handleClose}
                    >
                        <ChangeCompany user={user} userInfo={userInfo} openSnack={this.openSnack} handleClose={this.handleClose} changedCompany={this.changedCompany} />
                    </Modal> */}

                    <Snackbar open={snackOpen} autoHideDuration={3000} onClose={this.handleCloseSnack} >
                        <Alert onClose={this.handleCloseSnack} severity={snackStatus} variant="filled" >
                            {snackDescription}
                        </Alert>
                    </Snackbar>






                </Content> : null}

                <FindPath />

                <Footer style={{ fontFamily: 'Roboto, sans-serif', }}>
                    Powered by AquaSource Management, LLC © 2021.
                    <br />Contact: greg@do-profile.com
                </Footer>

                <Drawer open={this.state.visible} onClose={this.onClose}>
                    <List style={{ width: 250, color: 'rgba(0,0,0,.0.9)' }}>

                        <List>


                            <Link style={{ textDecoration: 'none', cursor: 'pointer', color: 'rgba(0,0,0,.0.9)' }} to="/">
                                <ListItem button key={"Profiles"} style={{ color: 'rgba(0,0,0,.0.9)' }} onClick={() => {
                                    this.onClose();

                                }}>
                                    <ListItemIcon><TimelineIcon /> </ListItemIcon>
                                    <ListItemText primary={"Profiles"} style={{ color: 'rgba(0,0,0,.0.9)' }} />
                                </ListItem>
                            </Link>





                            <Link style={{ textDecoration: 'none', cursor: 'pointer', color: 'rgba(0,0,0,.0.9)' }} to="/account">
                                <ListItem button key={"user"} style={{ color: 'rgba(0,0,0,.0.9)' }}
                                    onClick={() => {
                                        this.setState({ accountPage: "email" });
                                        this.onClose();
                                    }}>
                                    <ListItemIcon><AccountCircle /> </ListItemIcon>
                                    <ListItemText primary={"User"} style={{ color: 'rgba(0,0,0,.0.9)' }} />
                                </ListItem>
                            </Link>













                        </List>
                        <Divider />
                        <List>


                            <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingLeft: 10, paddingRight: 10, paddingTop: 15, width: '60%' }} to="/">
                                <Button type="danger" size="large" shape="round"
                                    onClick={this.signOut}>
                                    Sign Out
                            </Button>
                            </Link>
                        </List>





                    </List>
                </Drawer>
                <UseBreakpointDemo />
            </Layout>
        )
    }

}