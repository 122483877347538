import React from 'react';
import { Row, Col, Card, Rate, Pagination, Avatar, Input, message } from 'antd';
import { fire } from '../../../fire'; import firebase from 'firebase';
import { TextField, Button } from '@material-ui/core';

export default class AccountEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountInfo: this.props.accountInfo,
            emailSave: false,
            displaySave: false,
            user: this.props.user,
            displayName: "",
            email: "",
            adjustEmail: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountInfo !== prevProps.accountInfo) {

            this.setState({ accountInfo: this.props.accountInfo });


        }
        if (this.props.user !== prevProps.user) {

            this.setState({ user: this.props.user, email: this.props.user.email, displayName: this.props.user.displayName });


        }
    }

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {

            this.setState({ user, email: user.email, displayName: user.displayName });
            window.scrollTo(0, 0);


        });
    }

    emailSave = () => {
        this.setState({ emailSave: true })
    }
    displaySave = () => {
        this.setState({ displaySave: true })
    }

    render() {


        const InputHeader = (props) => {
            return (

                <Row gutter={[32,]} align="middle" >
                    <Col span={12} style={{ fontSize: 16 }}>
                        <b>{props.name}</b>
                    </Col>

                </Row>

            )

        }


        const { accountInfo, displaySave, emailSave, displayName, email, adjustEmail } = this.state;
        return (
            <div>
                <Card style={{ boxShadow: '3px 3px 8px #888888', fontFamily: 'Roboto, sans-serif', }}>
                    <Row gutter={[32, 32]} justify="space-around" >
                        <Col span={24} style={{ fontSize: 24 }}>
                            Change Email Address & Password
                      </Col>
                    </Row>

                    <InputHeader name="Display Name" required="(required)" />
                    <Row style={{ paddingBottom: 20 }}>
                        <Col span={12}>
                            <TextField key="displayName"
                                variant="outlined"
                                fullWidth
                                size="large"

                                value={displayName}
                                onChange={(e) => {

                                    const displayName = e.target.value;
                                    this.setState({ displayName })
                                    this.displaySave();

                                }}
                            />
                        </Col>
                    </Row>

                    {displaySave && (
                        <Row style={{ paddingBottom: 20 }}>
                            <Col span={24} >
                                <Button
                                    variant="contained" color="primary" size="large"
                                    onClick={() => {
                                        var user = fire.auth().currentUser;
                                        user.updateProfile({
                                            displayName: displayName,
                                        }).then(() => {
                                            message.success("Display Name Updated");



                                        }).catch(function (error) {
                                            // An error happened.
                                        });
                                        this.setState({ displaySave: false })
                                    }}>
                                    Update Display Name
                          </Button>
                            </Col>
                        </Row>
                    )}



                    <InputHeader name="Email" required="(required)" />
                    <Row style={{ paddingBottom: 20 }}>
                        <TextField key="email" style={{ borderRadius: '10px', }} size="large"
                            disabled={!adjustEmail}
                            value={email}
                            variant="outlined"
                            fullWidth
                            size="large"
                            onChange={(e) => {

                                const email = e.target.value;
                                this.setState({ email })


                            }}
                        />
                    </Row>




                    <Row style={{ paddingBottom: 20 }} gutter={[32, 8]}>
                        <Col sm={24} md={8} >

                            <Button style={{ borderColor: adjustEmail ? 'red' : null, color: adjustEmail ? 'red' : null, borderRadius: 8 }}
                               variant="contained" color="primary" size="large"
                                onClick={() => {

                                    this.setState({ adjustEmail: !adjustEmail })

                                    if (adjustEmail) {
                                        var user = fire.auth().currentUser;

                                        user.updateEmail(email).then(() => {
                                           
                                            message.success("Email Updated");
                                        }).catch(function (error) {
                                            
                                            message.error("Log out and log back in to change email.");
                                        });
                                    }

                                }}>
                                {adjustEmail ? "Save Email" : "Change Email"}
                            </Button>


                        </Col>
                        <Col sm={24} md={8} >

                            <Button style={{ borderRadius: 8 }}
                               variant="contained" color="secondary" size="large"
                                onClick={() => {
                                    var auth = fire.auth();
                                    var emailAddress = email;

                                    auth.sendPasswordResetEmail(emailAddress).then(function () {

                                    }).catch(function (error) {
                                      
                                    });
                                    this.setState({ displaySave: false })
                                }}>
                                Reset Password
                          </Button>


                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

}