import React from 'react';
import { Row, Col } from 'antd';
import { Breadcrumbs, Button, TextField, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default class SignUpForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            info: this.props.info,
            widthSize: this.props.widthSize,
            badInputs: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.widthSize !== prevProps.widthSize) {
            this.setState({ widthSize: this.props.widthSize });
        }
    }

    updateScreen = (size) => {
        this.setState({screenSize: size})
        
    }

    updateInfo = (item, value) => {
        const { info, badInputs } = this.state;
      

        if (value !== "") {
            const newInputs = badInputs.filter(a => a !== item);
            info[item] = value;
            this.setState({ info, badInputs: newInputs })
          
            this.props.updateInfo(info)
        }
        else {
            badInputs.push(item);
            info[item] = value;
            this.setState({ info, badInputs })
           
            this.props.updateInfo(info)
        }

    }

    submitInfo = (info) => {

    }

    render() {
        const { userInfo, account, info, badInputs, widthSize } = this.state;

        const BackButton = () => {
            return (
                <Button variant="contained" color="secondary" onClick={() => {

                    info.status = "planPick";
                    this.props.updateInfo(info)
                    this.setState({ info })
                }}>Go Back</Button>
            )
        }

        const SubmitInfoButton = () => {
            return (
                <Button variant="contained" color="primary" onClick={() => {
                    const firstName = info.firstName || "";
                    const lastName = info.lastName || "";
                    const displayName = info.displayName || "";
                    const email = info.email || "";
                    const password = info.password || "";
                    const confirmPassword = info.confirmPassword || "";
                    const company = info.company || "";
                    const agreeTerms = info.agreeTerms || false;

                    this.setState({ badInputs: [] });

                    const empties = [firstName, lastName, displayName, email, password, confirmPassword, company, agreeTerms].filter(a => a === "");

                    if (!email.includes("@") || !email.includes("."))
                    {

                        this.props.openSnack("error", "Please check your email");

                    }
                    else {

                        
                    if (password === confirmPassword)
                    {
                        if (empties.length === 0 && agreeTerms === true   ) {
                            info.status = "payment";
                            this.props.updateInfo(info)
                            this.setState({ info })
                        }
                        else {

                            this.props.openSnack("error", "Please check all information");
                            const object = { firstName, lastName, displayName, email, password, confirmPassword, company, agreeTerms};
                            Object.values(object).map((info, i) => {
                                if (info === "" || info === false) {
                                    badInputs.push(Object.keys(object)[i]);
                                }
                            });
                          
                            this.setState({ badInputs });
                        }
                    }   
                    else {
                        this.props.openSnack("error", "Passwords do not match");
                            const object = { firstName, lastName, displayName, email, password, confirmPassword, company, agreeTerms};
                            Object.values(object).map((info, i) => {
                                if (info === "" || info === false) {
                                    badInputs.push(Object.keys(object)[i]);
                                }
                            });
                           
                            this.setState({ badInputs });
                    }

                        
                    }




                        

                 
                }}>Next</Button>
            )
        }

        return (
            <div style={{ minHeight: '100vh', textAlign: 'center', paddingTop: widthSize=== "xs" ? 45 : null }}>


                <Col span={24} style={{ textAlign: 'center' }}>


                    <Row justify="space-around" gutter={[8, 16]} align="middle" >
                        <Col span={24} style={{ textAlign: 'center', fontSize: 28, fontFamily: 'Roboto, sans-serif', }}>
                            Pro Plan
                        </Col>

                        <Col span={24} style={{ textAlign: 'center', fontSize: 22, fontFamily: 'Roboto, sans-serif', color: "#3f51b5" }}>
                            <b>${info.plan.price}</b><span style={{ fontSize: 10 }}>/month</span>
                        </Col>
                        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>

                            <TextField id="outlined-basic" label="First Name" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                                value={info.firstName}
                                placeholder={"First Name"}
                                key={"firstName"}
                                onChange={(e) => {
                                    this.updateInfo("firstName", e.target.value)

                                }}
                                error={badInputs.includes("firstName")} />


                        </Col>


                        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                            <TextField id="outlined-basic" label="Last Name" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                                value={info.lastName}
                                placeholder={"Last Name"}
                                key={"lastName"}
                                onChange={(e) => {
                                    this.updateInfo("lastName", e.target.value)
                                }}
                                error={badInputs.includes("firstName")} />

                        </Col>
                        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                            <TextField id="outlined-basic" label="Display Name" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                                value={info.displayName}
                                placeholder={"Display Name"}
                                key={"displayName"}
                                onChange={(e) => {
                                    this.updateInfo("displayName", e.target.value)
                                }}
                                error={badInputs.includes("displayName")} />

                        </Col>
                        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                            <TextField id="outlined-basic" label="Email" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                                value={info.email}
                                autoComplete="off"
                                placeholder={"Email"}
                                key={"email"}
                                onChange={(e) => {
                                    this.updateInfo("email", e.target.value)
                                }}
                                error={badInputs.includes("email")} />


                        </Col>
                        <Col xs={24} sm={12} style={{ textAlign: 'right' }}>

                            <TextField id="outlined-basic" label="Password" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }} type="password"
                                value={info.password}
                                autoComplete="off"
                                placeholder={"Password"}
                                key={"password"}
                                onChange={(e) => {
                                    this.updateInfo("password", e.target.value)
                                }}
                                error={badInputs.includes("password")} />


                        </Col>
                        <Col xs={24} sm={12} style={{ textAlign: 'right' }} >

                            <TextField id="outlined-basic" label="Confirm Password" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }} type="password"
                                value={info.confirmPassword}
                                autoComplete="off"
                                placeholder={"Confirm Password"}
                                key={"confirmPassword"}
                                disabled={info.password.length > 4 ? false : true}
                                onChange={(e) => {
                                    this.updateInfo("confirmPassword", e.target.value)
                                }}
                                error={badInputs.includes("confirmPassword")} />


                        </Col>
                        <Col xs={24} sm={24} style={{ textAlign: 'right' }} >
                            <TextField id="outlined-basic" label="Company/Organization" fullWidth variant="outlined"
                                style={{ fontFamily: 'Roboto, sans-serif', fontSize: 18, }}
                                value={info.company}
                                autoComplete="off"
                                placeholder={"Company/Organization"}
                                key={"company"}
                                onChange={(e) => {
                                    this.updateInfo("company", e.target.value)
                                }}
                                error={badInputs.includes("company")} />
                        </Col>



                        <Col xs={24} sm={24} style={{ textAlign: 'left', fontFamily: 'Roboto, sans-serif', textAlign: 'center', fontSize: 14 }}>
                            <Checkbox style={{ border: badInputs.includes("agreeTerms") ? '0.5px solid red' : null }}
                                checked={info.agreeTerms === true}
                                onChange={(e) => {
                                    const info = this.state.info;
                                    info.agreeTerms = e.target.checked;
                                    this.setState({ info })
                                }} size="large" /> By Signing up as a member, I agree to DO-Profile's Privacy Policy & Terms and Conditions.

                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                            <SubmitInfoButton info={info} />
                        </Col>

                        <Col span={24} style={{ textAlign: 'center' }}>
                            <BackButton info={info} />
                        </Col>
                        
                    </Row>
                </Col>

            </div>
        )
    }

}