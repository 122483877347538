import React from 'react';
import { Row, Col, Popover, Spin } from 'antd';
import { TextField, Checkbox, Button, ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CompactPicker } from 'react-color';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default class PrameterReference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            profile: this.props.profile,
            parameter: this.props.parameter,
            newColor: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.parameter !== prevProps.parameter) {
            const parameter = this.props.parameter;
           
            this.setState({ parameter, })
        }
        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;
            this.setState({ profile, })
        }
    }

    componentDidMount() {
       
    }

    render() {
        const { userInfo, profile, parameter, newColor } = this.state;


       
            

        return (

            <Row align="middle" gutter={[4, 4]}>
               
            <Col span={8} style={{ textAlign: 'left', cursor: 'pointer' }}
               >
               <Button style={{textTransform: 'none'}} variant="contained" color={parameter.disable ? 'secondary' : 'primary'} 
               onClick={() => { if (parameter.disable) {
                parameter.disable = false;

                this.props.updateParameter(parameter)
                this.setState({ parameter })
            }
            else {
                parameter.disable = true;

                this.props.updateParameter(parameter)
                this.setState({ parameter })
            }

               }}>{parameter.newLabel}</Button> 
            </Col>

            <Col span={12} style={{ textAlign: 'left' }}>
                <TextField margin="dense"
                    value={parameter.customLabelText}
                    size="small"
                    style={{ fontSize: 10, }}
                    id="outlined-number"
                    label="Custom Label"
                    onChange={(e) => {

                        parameter.customLabelText = e.target.value;
                        this.props.updateParameter(parameter)
                        this.setState({ parameter })






                    }}
                    inputProps={{
                        style: {
                          height: '36px',
                          fontSize: '14px',
                          padding: '0 14px',
                        },
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
            </Col>
            <Col span={4} style={{ textAlign: 'left' }}>
                Use:
                                                    <Checkbox
                    
                    checked={parameter.customLabel}
                    onChange={(e) => {
                       
                        parameter.customLabel = e.target.checked;
                        this.props.updateParameter(parameter)
                        this.setState({ parameter })
                        
                       
                        
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Col>


            <Col span={3} style={{ textAlign: 'center' }}>

                {!newColor && parameter ?
                    <Popover content={(<CompactPicker
                        color={parameter.color}
                        onChangeComplete={(color) => {

                            this.setState({ newColor: true })
                            parameter.color = color.hex;
                            this.props.updateParameter(parameter);
                            setTimeout(() => {
                                this.setState({ parameter, newColor: false })
                            }, 250);
                        }}
                    />)} title="Change Color" trigger="click" style={{ fontFamily: 'Roboto, sans-serif', }}>

                        <div style={{ height: 20, width: '70%', backgroundColor: parameter.color, borderRadius: 6 }} />
                    </Popover>
                    : <Spin />}
            </Col>



            <Col span={3} style={{ textAlign: 'center' }}>
                Dots:
                                                    <Checkbox
                    checked={parameter.dots}
                    onChange={(e) => {
                        parameter.dots = e.target.checked;
                        this.props.updateParameter(parameter)
                        this.setState({ parameter })
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Col>


            <Col span={3} style={{ textAlign: 'center' }}>
                Hide:
                <Checkbox
                    defaultChecked={parameter.hideAxis}
                    onChange={(e) => {
                        parameter.hideAxis = e.target.checked;
                       
                        this.props.updateParameter(parameter)
                        this.setState({ parameter })
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                Color:
                <Checkbox
                    checked={parameter.colorAxis}
                    onChange={(e) => {
                        parameter.colorAxis = e.target.checked;
                        this.props.updateParameter(parameter)
                        this.setState({ parameter })
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                /> 
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
                Top:
                <Checkbox
                    checked={parameter.orientation === "top"}
                    onChange={(e) => {
                        if (e.target.checked === true)
                        {
                            parameter.orientation = "top";
                            this.props.updateParameter(parameter)
                            this.setState({ parameter })
                        }
                        else {
                            parameter.orientation = "bottom";
                            this.props.updateParameter(parameter)
                            this.setState({ parameter })
                        }
                       
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Col>










            {parameter && (
                <Col span={8}>

                    <Row align="middle">
                        <Col span={24}>
                            Domain:
                    </Col>


                        {!parameter.domainAuto && parameter && (
                            <Col span={10} style={{ textAlign: 'left' }}>
                                <TextField
                                    value={parameter.min}
                                    size="small"
                                    id="outlined-number"
                                    label="Min"
                                    type="number"
                                    onChange={(e) => {
                                        parameter.min = e.target.value;
                                        this.props.updateParameter(parameter)
                                        this.setState({ parameter })
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        style: {
                                          height: '36px',
                                          fontSize: '14px',
                                          padding: '0 14px',
                                        },
                                    }}
                                    variant="outlined"
                                />
                            </Col>
                        )}


                        {!parameter.domainAuto && (
                            <Col span={10} style={{ textAlign: 'left' }}>
                                <TextField
                                    value={parameter.max}
                                    size="small"
                                    onChange={(e) => {
                                        parameter.max = e.target.value;
                                        this.props.updateParameter(parameter)
                                        this.setState({ parameter })
                                    }}
                                    id="outlined-number"
                                    label="Max"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        style: {
                                          height: '36px',
                                          fontSize: '14px',
                                          padding: '0 14px',
                                        },
                                    }}
                                    variant="outlined"
                                />
                            </Col>
                        )}

                        <Col span={4} style={{ textAlign: 'left' }}>
                            <Checkbox
                                checked={parameter.domainAuto}
                                onChange={(e) => {
                                    parameter.domainAuto = e.target.checked;
                                    this.props.updateParameter(parameter)
                                    this.setState({ parameter })
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Col>
                    </Row>



                </Col>
            )}





            <Col span={24}>
                <hr />
            </Col>

        </Row>

        )



    }

}