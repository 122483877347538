import { Button, Snackbar } from '@material-ui/core';
import { Col, Grid, Layout, Row, Spin } from 'antd';
import React, { PureComponent } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { fire } from '../../fire';
import LandingPage from '../../components/external/landingPage/landingPage';
import Login from '../../components/external/login/login';
import { Alert } from '@material-ui/lab';
import BuildProfileHome from '../external/build/buildProfileHome';
import Logo from '../../common/assets/images/logo/logo.png';
import Signup from '../external/signUp.jsx/signup';
import Privacy from '../external/policy/privacy';
import Terms from '../external/policy/terms';




const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

export default class SignedInRouting extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            equipmentKey: '',
            orderKey: '',
            userInfo: {},
            loadScene: false,
            widthSize: "lg",
            body: "default",
            snackOpen: false,
            snackStatus: '',
            snackDescription: ''
        };
    }





    updateScreen = (size) => {
        this.setState({ widthSize: size })

    }

    openSnack = (snackStatus, snackDescription) => {
        this.setState({ snackOpen: true, snackStatus, snackDescription })
    }

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            window.scrollTo(0, 0);
            this.setState({ user });




        });
    }

    handleCloseSnack = () => {
        this.setState({ snackOpen: false })
    }

    render() {

        const Loading = () => {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row justify="space-around" gutter={[32, 8]} align="middle" style={{ paddingTop: 18, }}>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            Loading...
                 </Col>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 24, fontFamily: 'Roboto, sans-serif', }}>
                            <Spin size="large" />
                        </Col>
                    </Row>
                </div>
            )
        }




        const UseBreakpointDemo = () => {
            const screens = useBreakpoint();

            const screenItems = Object.entries(screens);
            const filterScreens = screenItems.filter(screen => !!screen[1]);

            const filterLength = filterScreens.length;
            const size = filterScreens[filterLength - 1];

            if (size !== undefined && this.state.widthSize !== undefined && size[0] !== this.state.widthSize) {


                this.updateScreen(size[0]);
            }


            return (
                null
            )
        }

        const { loadScene, widthSize, body, snackOpen, snackStatus, snackDescription } = this.state;


        const LogoItem = () => {
            const history = useHistory();
            return (
                <img src={Logo} style={{ maxWidth: '200px', }} onClick={() => {
                    history.push("/home")
                }} />
            )
        }
        return (
            <div style={{ minHeight: '100vh' }}>
                <Layout style={{ minHeight: '100vh', fontFamily: 'Roboto, sans-serif', backgroundColor: '#ffffff', }}>
                    <Header style={{
                        zIndex: 3,
                        backgroundColor: '#ffffff', width: '100%', height: '100px', color: 'white', padding: '0 30px',
                    }}>
                        <Row style={{ paddingTop: 26, display: "flex", alignItems: "center" }} gutter={[8, 8]}>
                            <Col xs={24} sm={15} style={{ cursor: 'pointer' }} >
                                <LogoItem />
                            </Col>




                            <Col xs={24} sm={3} style={{ textAlign: 'right' }}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/">
                                    <Button variant="link" size="large" color="primary" style={{ textTransform: 'capitalize', fontFamily: 'Roboto, sans-serif', }} >
                                        Build Now
                                    </Button>
                                </Link>
                            </Col>




                            <Col xs={24} sm={3} style={{ textAlign: 'right' }}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/signUp">
                                    <Button variant="link" color="primary" size="large" style={{ textTransform: 'capitalize', fontFamily: 'Roboto, sans-serif', }} >
                                        Sign Up
                                </Button>
                                </Link>

                            </Col>
                            <Col xs={24} sm={3} style={{ textAlign: 'right' }}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/login">
                                    <Button variant="link" color="primary" size="large" style={{ textTransform: 'capitalize', fontFamily: 'Roboto, sans-serif', }} >
                                        Log In
                                </Button>
                                </Link>

                            </Col>

                        </Row>
                    </Header>



                    <Content style={{ backgroundColor: 'white' }}>

                        <Row style={{ paddingTop: 15 }}>
                            <Col offset={1} span={22} >
                            <Route exact path="/" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <BuildProfileHome key="login" openSnack={this.openSnack} widthSize={widthSize} /> : <Loading />
                                    )
                                }} />
                                <Route exact path="/home" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <LandingPage key="/home" widthSize={widthSize} /> : <Loading />
                                    )
                                }} />




                                <Route exact path="/login" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <Login key="login" widthSize={widthSize} /> : <Loading />
                                    )
                                }} />
                               

                                <Route exact path="/signUp" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <Signup key="sign up" openSnack={this.openSnack} widthSize={widthSize} /> : <Loading />
                                    )
                                }} />
                                <Route exact path="/privacy" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <Privacy key="privacy" openSnack={this.openSnack} widthSize={widthSize} /> : <Loading />
                                    )
                                }} />
                                <Route exact path="/terms" render={() => {
                                    setTimeout(() => {
                                        this.setState({ loadScene: true });
                                    }, 1000);

                                    return (
                                        loadScene ? <Terms key="terms" openSnack={this.openSnack} widthSize={widthSize} /> : <Loading />
                                    )
                                }} />















                            </Col>
                        </Row>



                        <Snackbar open={snackOpen} autoHideDuration={3000} onClose={this.handleCloseSnack} >
                            <Alert onClose={this.handleCloseSnack} severity={snackStatus} variant="filled" >
                                {snackDescription}
                            </Alert>
                        </Snackbar>



                    </Content>


                    <Footer style={{ fontFamily: 'Roboto, sans-serif', }}>
                        <Row gutter={[16, 16]}>

                            <Col span={8}>
                                Powered by AquaSource Management, LLC © 2021.
                                <br />Contact: greg@do-profile.com
                            </Col>
                            <Col span={8}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/privacy">
                                    <Button variant="link" size="small" style={{ textTransform: 'capitalize' }}>Privacy Policy</Button>
                                </Link>
                            </Col>
                            <Col span={8}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', paddingTop: 15 }} to="/terms">
                                    <Button variant="link" size="small" style={{ textTransform: 'capitalize' }}>Terms & Conditions</Button>
                                </Link>
                            </Col>

                        </Row>


                    </Footer>

                    <UseBreakpointDemo />
                </Layout>
            </div>


        )
    }

}