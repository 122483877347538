import React from 'react';
import { Row, Col, Card, Rate, Pagination, Button, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AccountEmail from './accountEmail';

import AccountSummary from './accountSummary'; 
import AccountCards from './accountCards';
import AccountHistory from './accountHistory';
import AccountPurchased from './accountPurchased';
import { fire } from '../../../fire';

export default class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountItem: "email",
            accountInfo: this.props.accountInfo,
            userInfo: this.props.userInfo,
            user: {},
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountInfo !== prevProps.accountInfo) {
            this.setState({ accountInfo: this.props.accountInfo });
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo });
        }
        
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                
               this.setState({user, })


            } else {
                // User is signed out.
                // ...
            }
        });
    }
    updateShippingAddress = (address) => {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ address })
              
                const db = fire.firestore();

                db.collection("users")
                .doc(user.uid)
                .collection("accountInfo")
                .doc("shippingAddress")
                .set({...address, label: "shippingAddress"});


            } else {
                // User is signed out.
                // ...
            }
        });
    }

    

    render() {

        const styles = {
            buttonsStyles: {
                margin: 0, color: '#000000a6', background: '#f7f7f7', height: 70,
                border: '1px solid rgba(0,0,0,.1)'
            }
        }

        const { accountInfo, accountItem, user, puzzleCredit, userInfo } = this.state;
        return (
            <div style={{ minHeight: '100vh' }}>
                <Row gutter={[32,]} style={{fontFamily: 'Raleway, sans-serif',}}>
                    <Col xs={24} sm={8}>
                        <Card bodyStyle={{ padding: 0 }} bordered={false}>
                            <Row>
                                <Col span={24} style={{ fontSize: 28, paddingBottom: 8 }}>
                                    Account Menu
                            </Col>
                            <Col xs={12} sm={24}>
                                    <Button size="large" type="link" block style={styles.buttonsStyles}
                                    onClick={() => {
                                        this.setState({accountItem: "email"})
                                    }}>Email Address & <br /> Password</Button>
                                </Col>
                            <Col xs={12} sm={24}>
                                    <Button size="large" type="link" block style={styles.buttonsStyles}
                                    onClick={() => {
                                        this.setState({accountItem: "summary"})
                                    }}>Subscription Summary</Button>
                                </Col>
                                
                               
                                <Col xs={12} sm={24}>
                                    <Button size="large" type="link" block style={styles.buttonsStyles}
                                    onClick={() => {
                                        this.setState({accountItem: "cards"})
                                    }}>Credit Cards</Button>
                                </Col>
                              

                            </Row>

                        </Card>
                    </Col>
                    <Col xs={24} sm={16} style={{ paddingTop: 50 }}>

                        {accountItem === "summary" ? <AccountSummary accountInfo={accountInfo} user={user} puzzleCredit={puzzleCredit} userInfo={userInfo}  signedOut={this.props.signedOut}/> : null}
                        {accountItem === "email" ? <AccountEmail accountInfo={accountInfo} user={user} /> : null}
                        {accountItem === "cards" ? <AccountCards accountInfo={accountInfo} user={user} userInfo={userInfo}  /> : null}
                        {accountItem === "history" ? <AccountHistory accountInfo={accountInfo} user={user} /> : null}
                        {accountItem === "purchased" ? <AccountPurchased accountInfo={accountInfo} user={user} /> : null}
                        
                        
                    </Col>
                </Row>
            </div>
        )
    }

}