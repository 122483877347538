import React from 'react';
import { Row, Col, Tabs, Popover } from 'antd';
import { Breadcrumbs, Button, Fab } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import HorizontalNonLinearStepper from './verticalSteps';
import ProfileDataDrop from './profileDataDrop';
import MapKeys from './mapKeys';
import Profile from './profile';
import { fire } from '../../../fire';


const { TabPane } = Tabs;

export default class BuildProfileHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            profile: this.props.profile
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;
            
            this.setState({ profile, })
        }
    }

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            window.scrollTo(0, 0);
            this.setState({ user });

            const db = fire.firestore();
            var userRef = db.collection("users").doc(user.uid);
            userRef.get().then((doc) => {
                const userInfo = doc.data();
                this.setState({ userInfo })
               
                var profileRef = db.collection("users")
                    .doc(userInfo.id)
                    .collection("profiles")
                    .doc(userInfo.currentProfile);
                profileRef.get().then((doc) => {
                    const profile = doc.data();
                    

                    if (profile === undefined || Object.values(profile).length === 0) {
                        const profile = {
                            step: "addData",
                            keys: [],
                            data: [],
                            mappedKeys: {},
                            height: 600,
                            width: '50%',
                            yAxis: "",
                            surface: true,
                            graphData: {},
                            references: {},
                            profileSettings: false
                        };

                        this.setState({ profile })
                       
                    }
                    else {
                        this.setState({ profile, loading: false, })
                       
                    }








                })
            });
        });

    }

    updateStep = (step) => {
        const { profile } = this.state;
        profile.step = step;
        this.setState({ profile })
       
    }
    updateProfile = (item, value) => {
        const { profile } = this.state;
        profile[item] = value;

        if (item === "keys") {
            const mappedKeys = {};
            value.map((k) => {
                mappedKeys[k] = { ogLabel: k, newLabel: "" }
            })
            profile.mappedKeys = mappedKeys;
            this.setState({ profile })
        }
        else {
            this.setState({ profile })
           
        }

    }




    updateSettings = (item, value) => {
        const { profile } = this.state;
        profile[item] = value;
        this.setState({ profile })
       
    }



    render() {
        const { userInfo, account, step, profile, profileSettings } = this.state;

       

        const Content = () => {
            let history = useHistory();
            const userInfo = this.state.userInfo;
         
            return (
                <div style={{ textAlign: 'center' }}>
                    <p>Are you sure you want to delete this profile?</p>
                    <Button variant="contained" color="secondary"
                        onClick={() => {
                            const db = fire.firestore();
                            db.collection("users")
                                .doc(userInfo.id)
                                .collection("profiles")
                                .doc(profile.key)
                                .delete()
                                .then(() => {
                                    history.push('/')
                                })


                        }}>
                        Yes
                </Button>
                </div>

            )
        }

        const ReturnButton = () => {
            let history = useHistory();
            return (
                <Button variant="outlined" color="primary" onClick={() => {
                    history.push("/")
                }}>
                    Return to Profiles
                </Button>
            )
        }

        return (
            <div style={{ minHeight: '100vh' }}>
                <Row gutter={[16, 8]} align="middle" style={{ fontFamily: 'Roboto, sans-serif', color: 'rgba(0,0,0,.7)' }}>
                <Col span={24} style={{ fontSize: 36 }}>
                        <ReturnButton />
                    </Col>

                    
                    <Col span={24} style={{ fontSize: 36, textAlign: 'center' }}>
                        Profile
                    </Col>


                    <Col xs={24} style={{ fontSize: 22, textAlign: 'center' }}>
                        {profile.step === "addData" ? "Step 1: Add Data, Drag and Drop csv/xls Below." :
                            profile.step === "mapKeys" ? "Step 2: Map Keys" :
                                profile.step === "buildProfile" ? "Step 3: Profile" : null
                        }
                    </Col>

                    <Col xs={24} sm={{offset:20, span: 2}} style={{ textAlign: 'right' }}
                    >
                        {profile.step !== "addData" && (<Button variant="contained" color="primary" fullWidth
                            onClick={() => {
                                if (profile.step !== "addData") {

                                    const newStep = profile.step === "buildProfile" ? "mapKeys" : profile.step === "mapKeys" ? "addData" : "addData";
                                    profile.step = newStep;
                                   
                                    this.setState({ profile })
                                }

                            }}>Back</Button>)}
                    </Col>

                    <Col xs={24} sm={2} style={{ textAlign: 'right' }}>
                    {profile.step === "buildProfile" && ( <Popover content={Content} title="Delete Category" trigger="click" style={{ fontFamily: 'Roboto, sans-serif', }}>
                            <Button variant="contained" color="secondary" fullWidth  >Delete</Button>
                        </Popover> )}
                    </Col>

                    <Col span={24}>
                        {profile.step === "addData" && (
                            <ProfileDataDrop updateStep={this.updateStep} updateProfile={this.updateProfile} openSnack={this.props.openSnack} />
                        )}

                    </Col>

                    <Col span={24}>
                        {profile.step === "mapKeys" && (
                            <MapKeys updateStep={this.updateStep} profile={profile} updateProfile={this.updateProfile} openSnack={this.props.openSnack} />
                        )}

                    </Col>
                    <Col span={24}>
                        {profile.step === "buildProfile" && (
                            <Row >

                                <Profile updateStep={this.updateStep} profile={profile} updateProfile={this.updateProfile} openSnack={this.props.openSnack} userInfo={userInfo} />
                            </Row>
                        )}

                    </Col>




                </Row>
            </div>
        )
    }

}