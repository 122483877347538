import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import React from 'react';
import Router from './components/routing/router';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-190668187-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>
      <Router /> 
    </div>
  );
}

export default App;
