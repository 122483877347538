import React from 'react';
import { Row, Col, Card } from 'antd';
import { Breadcrumbs, Fab, Button, TextField, InputAdornment } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { fire } from '../../fire';
import TimelineIcon from '@material-ui/icons/Timeline';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: this.props.userInfo,
      account: this.props.account,
      profiles: [],
      filteredProfiles: [],
      searchInput: ''
    };
  }

  componentDidMount() {
    const { userInfo } = this.state;

   

    const db = fire.firestore();
    db.collection("users")
      .doc(userInfo.uid)
      .collection("profiles")
      .onSnapshot((snapshot) => {
        const profiles = [];
        snapshot.forEach((doc) => {
          profiles.push({ ...doc.data() })
        })

       

        this.setState({ profiles, filteredProfiles: profiles })
      });
  }




  render() {
    const { userInfo, account, profiles, searchInput, filteredProfiles } = this.state;
    const boxShadow = 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px';

    const AddButton = () => {
      let history = useHistory();

      return (
        <Fab color="primary" aria-label="add" size="large"
          onClick={() => {
           
            const db = fire.firestore();
            db.collection("users")
              .doc(userInfo.uid)
              .update({ currentProfile: "none" })
              .then(() => {
                history.push("/buildProfile");
              })

          }}>
          <AddIcon />
        </Fab>
      )
    }
    const AddButtonLong = () => {
      let history = useHistory();

      return (
        <Button variant="contained" color="primary" fullWidth size="large"
          onClick={() => {
          
            const db = fire.firestore();
            db.collection("users")
              .doc(userInfo.uid)
              .update({ currentProfile: "none" })
              .then(() => {
                history.push("/buildProfile");
              })

          }}>
          Add Profile<AddIcon />
        </Button>
      )
    }

    const ViewLabel = ({profile}) => {
      let history = useHistory();

      return (
        <Col span={20} style={{fontSize: 30, cursor: 'pointer'}} 
        onClick={() => {
          const db = fire.firestore();
          db.collection("users")
            .doc(userInfo.uid)
            .update({ currentProfile: profile.key })
            .then(() => {
              history.push("/buildProfile");
              this.props.updateProfile(profile);
            })

        }}>
        <span><TimelineIcon color="primary" />  </span>{profile.name}
      </Col>
      )
    }



    const ViewCard = ({profile, keyTag}) => {
      let history = useHistory();


      return (
        <Row align="middle" gutter={[8,8]} style={{padding: 10}}>
            <ViewLabel profile={profile} />
          <Col span={4} >
          <Button color="primary" variant="outlined" size="small"
          onClick={() => {
            const db = fire.firestore();
            db.collection("users")
              .doc(userInfo.uid)
              .update({ currentProfile: keyTag })
              .then(() => {
                history.push("/buildProfile");
                this.props.updateProfile(profile);
              })

          }}>
          View
        </Button>
          </Col>

          <Col span={24}>
            <hr />
          </Col>
          <Col span={24} style={{fontSize: 18}}>
           <span>Last Saved:</span> {moment(profile.lastSaved, "X").format('MM/DD/YYYY')}
          </Col>
          
          <Col span={24} style={{fontSize: 18}}>
           <b>Parameters</b>
          </Col>
          <Col span={24} style={{fontSize: 18}}>
            {Object.values(profile.graphData).map((d) => {
              return (
                <Row align="middle" gutter={[8,8]}>
                  <Col span={12}>
                    {d.newLabel}
                  </Col>
                </Row>
              )
            })}
          </Col>
        </Row>
       
      )
    }

    return (
      <div style={{ minHeight: '100vh' }}>
        <Row gutter={[16, 8]} justify="space-between" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 12, color: 'rgba(0,0,0,.7)', paddingTop: 30 }}>
          <Col xs={20} sm={22} style={{ fontFamily: 'Roboto, sans-serif', fontSize: 40, display: "flex", alignItems: "center" }}>
            <span style={{ paddingLeft: 4 }}><b>Your Profiles</b></span>

          </Col>
          <Col xs={0} sm={2} style={{ textAlign: 'right' }} >
            <AddButton />
          </Col>
          <Col xs={24} sm={0} style={{ textAlign: 'right' }} >
            <AddButtonLong />
          </Col>
          <Col xs={24} sm={24} md={24} style={{ fontSize: 14, fontFamily: 'Roboto, sans-serif' }}>
                        <TextField id="search" label="Search Profiles"  variant="outlined" size="small"
                            style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14, }}
                            value={searchInput}
                            disabled={profiles.length === 0 ? true : false}
                            placeholder={"Search Profiles"}
                            key={"search"}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                            }}

                            onChange={(e) => {

                                this.setState({ searchInput: e.target.value })
                                if (e.target.value.length > 1) {

                                    const filteredProfiles = profiles.filter((a) => a.name.toLowerCase().includes(e.target.value.toLowerCase()))
                                    console.log(filteredProfiles)
                                    this.setState({ filteredProfiles })
                                }
                                if (e.target.value.length < 3 && filteredProfiles !== profiles) {
                                    this.setState({ filteredProfiles: profiles })
                                }
 
                            }}
                        />


                    </Col>
          <Col xs={24} >
            <hr />
          </Col>
          <Col xs={24} >
            <Row gutter={[16,16 ]}>
              {filteredProfiles.length > 0 ?  filteredProfiles.sort((a,b) => a.name.localeCompare(b.name)).map((p) => {

                return (
                  <Col xs={24} sm={8}>
                    <Card style={{ boxShadow, }}>
                      
                      <ViewCard profile={p} keyTag={p.key} />
                      
                    </Card>
                  </Col>
                )
              }) : <Col span={24} style={{fontSize: 16}}> {searchInput !== "" && filteredProfiles.length === 0 ? "No Results from Search." : "No profiles created."}</Col>}
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

}