import firebase from 'firebase';
var firebaseConfig = {
  apiKey: "AIzaSyDnpCOAc_wapEwAZQpeUhT5T41z3vSzGB0",
  authDomain: "doprofile-fe8ca.firebaseapp.com",
  projectId: "doprofile-fe8ca",
  storageBucket: "doprofile-fe8ca.appspot.com",
  messagingSenderId: "474095046484",
  appId: "1:474095046484:web:8c696d35557a967dc782c1",
  measurementId: "G-3HN8WMKEMV"
};
const fire = firebase.initializeApp(firebaseConfig);
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { fire, facebookProvider }
