import React from 'react';
import { Row, Col } from 'antd';
import { FormControl, InputLabel, Select, MenuItem, Button, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MappedValues from '../../../extra/mappedValues.json';
import { withStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import uuid from 'react-uuid';

var randomColor = require('randomcolor');

const homePageStyle = (theme) => ({
    root: {
        width: "300px"
    },
    selected: {
        backgroundColor: "#3f51b5c2 !important",
        color: "white",
        fontWeight: 600
    }
});

class MapKeys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            profile: this.props.profile,
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;

            this.setState({ profile, })
        }
    }



    render() {
        const { userInfo, account, profile, params } = this.state;
        const { classes } = this.props;

        return (

            <Row gutter={[16, 24]} justify="center" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 12, color: 'rgba(0,0,0,.7)' }}>

                <Col span={16} >
                    <Row gutter={[8, 24]} align="middle" justify="center">
                        {profile.keys.map((k) => {

                            return (
                                <Col span={24}>
                                    <Row justify="center" align="middle" gutter={[8, 8]}> 

                                        
                                        <Col span={10}>

                                            <span style={{fontSize: 16, paddingBottom: 4}}>{k}: </span>

                                            <FormControl variant="outlined" style={{ width: '100%', paddingTop: 4 }} >
                                                <InputLabel id="demo-simple-select-label">Parameter</InputLabel>
                                                <Select

                                                    labelId="demo-simple-select-outlined-label"
                                                    id="Parameter"
                                                    variant="outlined"

                                                    value={profile.mappedKeys[k].key || ""}


                                                    onChange={(e, props) => {


                                                        profile.mappedKeys[k] = { ...profile.mappedKeys[k], newLabel: props.props.newLabel, units: props.props.units, key: e.target.value, type: props.props.type }
                                                        this.setState({ profile })
                                                        this.props.updateProfile("mappedKeys", profile.mappedKeys)

                                                    }}

                                                    label="Parameter"
                                                    placeholder="Parameter"
                                                    fullWidth
                                                >
                                                    {MappedValues.sort((a, b) => a.label.localeCompare(b.label)).map((s) => {
                                                        return (
                                                            <MenuItem value={s.key} newLabel={s.label} type={s.type} units={s.units} selected classes={{ selected: classes ? classes.selected : null }} size="small">{s.label}</MenuItem>
                                                        )



                                                    })}
                                                </Select>
                                            </FormControl>


                                        </Col>
                                        <Col span={2}>

                                            <Button variant="link" color="primary" size="large"
                                                onClick={() => {
                                                    const index = profile.keys.findIndex((item) => k === item);
                                                 
                                                    const newKeys = profile.keys;
                                                    newKeys.splice(index, 1);
                                                    profile.keys = newKeys;
                                                    delete profile.mappedKeys[k];
                                                    this.setState({ profile })
                                                }}
                                            ><DeleteOutlineIcon /></Button>
                                        </Col>
                                    </Row>
                                </Col>


                            );
                        })}

                        <Col span={12}  style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" size="large" fullWidth
                                disabled={profile.keys.length !== Object.values(profile.mappedKeys).filter((f) => f.newLabel !== "").length}
                                onClick={() => {
                                    const depthItems = Object.values(profile.mappedKeys).filter((f) => f.key.toLowerCase().includes("depth")).length;

                                    if (depthItems === 0) {
                                        this.props.openSnack("error", "Please add a depth parameter")
                                    }
                                    else {
                                        if (depthItems > 1) {
                                            this.props.openSnack("error", "Only 1 Depth Parameter")
                                        }
                                        else {
                                            const graphData = {};
                                            Object.values(profile.mappedKeys).map((m) => {

                                                const key = uuid();

                                                const item = {
                                                    key,
                                                    label: m.ogLabel,
                                                    units: m.units,
                                                    newLabel: m.newLabel,
                                                    yAxis: false,
                                                    color: randomColor(),
                                                    domainAuto: true,
                                                    customDomain: ['auto', 'auto'],
                                                    hideAxis: false,
                                                    colorAxis: false,
                                                    graphType: "line",
                                                    min: 0,
                                                    max: 20,
                                                    type: m.type,
                                                    disable: false,
                                                    dots: true,
                                                    customLabel: false,
                                                    customLabelText: '',
                                                    orientation: 'bottom'

                                                };

                                                if (m.newLabel.toLowerCase().includes("depth")) {

                                                    graphData[key] = item;
                                                    graphData[key].yAxis = true;
                                                }
                                                else {
                                                    graphData[key] = item;
                                                }

                                            })
                                            
                                            this.props.updateProfile("graphData", graphData)
                                            this.props.updateStep("buildProfile")
                                        }
                                    }



                                }}
                            >Build Graph</Button>
                        </Col>
                    </Row>
                </Col>


            </Row>

        )
    }

}

export default withStyles(homePageStyle)(MapKeys);