import React from 'react';
import { Row, Col, Card, Popover } from 'antd';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CompactPicker } from 'react-color';

export default class ProfileSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            account: this.props.account,
            profile: this.props.profile,
            showSettings: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;
            this.setState({ profile, })
        }
    }

    componentDidMount() {
       
        setTimeout(() => {
            this.setState({ showSettings: true,  });
        }, 500);
    }

    render() {
        const { userInfo, account, profile, showSettings } = this.state;

        if (!showSettings)
        {
            return null;
        }
        else {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Row gutter={[16, 8]} justify="space-between" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 12, color: 'rgba(0,0,0,.7)' }}>
                        <Col span={24} style={{ textAlign: 'center', fontSize: 36 }}>
                            Profile Settings
              </Col>
                        <Col span={24} style={{ textAlign: 'center', }}>
                        <Row>
                            <Col span={6}>
                                Parameter
                                    </Col>
    
                            <Col span={3}>
                                Color
                                    </Col>
                            <Col span={8}>
                                Domain (Auto)
                                    </Col>
                            <Col span={3}>
                                Show X Axis
                                    </Col>
                            <Col span={3}>
                                Color Axis
                                    </Col>
                            <Col span={24}>
                                <hr />
                            </Col>
                        </Row>
                        <Row gutter={[8,8]}>
                            {Object.values(profile.graphData).map((g, i) => {

                                if (g.type !== "depth")
                                {
                                    return (
                                        <Col span={24}>
                                       
                                            <Row>
                                            <Col span={6} style={{textAlign: 'left'}}>
                                            {g.label}
                                            </Col>
                                            <Col span={3} style={{textAlign: 'left'}}>
    
                                            <Popover content={(<CompactPicker
                                                color={g.color}
                                                onChangeComplete={(color) => {
                                                    profile.graphData[i].color = color.hex;
                                                    this.setState({profile})
                                                    
                                                    this.props.updateGraphData(i, 'color', color.hex)
                                                }}
                                            />)} title="Change Color" trigger="click" style={{ fontFamily: 'Roboto, sans-serif', }}>
    
                                                <div style={{ height: 20, width: '70%', backgroundColor: g.color, borderRadius: 6 }} />
                                            </Popover>
                                        </Col>
                                        </Row>
                                       
                                        </Col>
                                  
                                )
                                }
                                
                            })}
                              </Row>
                        </Col>
                    </Row>
                </div>
            )

        }
        
    }

}