import React from 'react';
import { Row, Col, Card, Rate, Pagination, Button, Avatar, Input, Spin, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { fire } from '../../../fire';
import UpdateCard from './changeCard';

export default class AccountCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountInfo: this.props.accountInfo,
            userInfo: this.props.userInfo,
            cardinfo: {},
            loading: true,
            user: this.props.user,
            card: {},
            visible: false,

        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.accountInfo !== prevProps.accountInfo) {
            this.setState({ accountInfo: this.props.accountInfo, });
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo, });
        }
    }

    componentDidMount() {

        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {
            window.scrollTo(0, 0);
           
            this.setState({ userInfo: user });

            const db = fire.firestore();
            db.collection("users")
                .doc(user.uid)
                .collection("accountInfo")
                .onSnapshot((snapshot) => {
                    const accountInfoList = [];
                    snapshot.forEach((doc) => {
                        accountInfoList.push({ ...doc.data() })
                    })
                    const accountInfo = {};
                    accountInfoList.forEach((a) => {
                        accountInfo[a.label] = a;
                    })
                  
                    const payment = accountInfo.payment || {};
                    const card = payment.card || {};
                    this.setState({ card, accountInfo, loading: false })
                })

        });


    }

    showModal = () => {

        this.setState({
            visible: true,
        });




    };

    handleOk = e => {

        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {

        this.setState({
            visible: false,
        });
    };


    render() {


        const InputHeader = (props) => {
            return (

                <Row gutter={[32,]} align="middle" >
                    <Col span={12} style={{ fontSize: 16 }}>
                        <b>{props.name}</b>
                    </Col>

                </Row>

            )

        }


        const { accountInfo, loading, card, user, userInfo } = this.state;
        return (
            <div>
                <Card style={{ boxShadow: '3px 3px 8px #888888', }}>
                <Row gutter={[32, 32]}  >
                            <Col xs={24} sm={16} style={{ fontSize: 28 }}>
                                Account Summary
                          </Col>
                         
                        </Row>
                      

                    {accountInfo && accountInfo.subscription ?
                        <Row gutter={[32, 32]}>
                            {loading ? <Spin /> :
                                <Col span={24}>
                                    <Col span={24} style={{ fontSize: 18 }}>
                                        Current Card: {card.brand}
                                    </Col>
                                    <Col span={24} style={{ fontSize: 18 }}>
                                        XXXX-XXXX-XXXX-{card.last4}
                                    </Col>
                                    <Col span={24} style={{ fontSize: 18 }}>
                                        Expiration: {card.exp_month}-{card.exp_year}
                                    </Col>
                                    <Col span={24} style={{ fontSize: 18 }}>
                                        <Button type="default" shape="round"
                                            onClick={this.showModal}>Change Card</Button>
                                    </Col>
                                </Col>}
                        </Row>
                        : <Row style={{ padding: 1 }}>
                            <Col span={24} style={{ textAlign: 'center' }}>It looks like you are not a member yet,
                      <br /> click below to sign up!</Col>
                            <Col span={24} style={{ textAlign: 'center', paddingTop: 6 }}>
                                <Link style={{ textDecoration: 'none', cursor: 'pointer', }} to="/planPick">
                                    <Button type="primary" size="large" style={{ borderRadius: 8 }}>Subscribe</Button>
                                </Link>
                            </Col>

                        </Row>}



                    <Modal
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        footer={[
                            null
                        ]}
                    >

                        <Row gutter={[8, 8]}>




                            <Col span={24}>

                                <UpdateCard user={user} userInfo={userInfo} accountInfo={accountInfo} />

                            </Col>




                        </Row>
                    </Modal>



                </Card>
            </div>
        )
    }

}