import React from 'react';
import { Row, Col, Popover, Spin } from 'antd';
import { TextField, Checkbox, Button, ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CompactPicker } from 'react-color';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default class ProfileReference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            profile: this.props.profile,
            reference: this.props.reference,
            newColor: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.reference !== prevProps.reference) {
            const reference = this.props.reference;
            
            this.setState({ reference, })
        }
        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;
            this.setState({ profile, })
        }
    }

    componentDidMount() {
       
    }

    render() {
        const { userInfo, profile, reference, newColor } = this.state;

        const DeleteReference = (keyTag) => {
            return (
                <Button variant="link" color="primary" size="large"
            onClick={() => {
                this.props.deleteReference(reference);
            }}
        ><DeleteOutlineIcon /></Button>
            )
            
        }
        const SaveReference = () => {
            return (
                <Button variant="contained" color="primary" size="small"
            onClick={() => {
                reference.save = false;
                this.setState({reference})
                this.props.updateReference(reference);
            }}
        >Save</Button>
            )
            
        }

        if (reference !== undefined)
        {
            return (

                <Row align="middle" gutter={[4, 4]}>
                    <Col span={12} style={{ fontSize: 12}}>
                        <TextField
                            value={reference.label}
                            size="small"
                            style={{ fontSize: 12}}
                            id="outlined-number"
                            label="Label"
                            onChange={(e) => {
    
                                reference.label = e.target.value;
                                this.props.updateReference(reference);
                                this.setState({ reference })
    
    
    
    
    
    
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
    
                    </Col>
    
                    <Col span={6} style={{ fontSize: 12}}>
                        <TextField
                            value={reference.yValue}
                            size="small"
                            style={{ fontSize: 12}}
                            label="yValue"
                            type="number"
                            InputProps={{
                                inputProps: { 
                                    max: 100, min: 0 
                                }
                            }}
                            onChange={(e) => {
                                reference.yValue = e.target.value;
                                this.props.updateReference(reference);
                                this.setState({ reference })
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Col>
                    <Col span={3} >
                    Color: 
                    </Col>
                    <Col span={3} >
                       
                        {!newColor && reference ?
                            <Popover content={(<CompactPicker
                                color={reference.color}
                                onChangeComplete={(color) => {
                                    this.setState({newColor: true})
                                    reference.color = color.hex;
                                    this.props.updateReference(reference);
                                    setTimeout(() => {
                                        this.setState({ reference, newColor: false  })
                                    }, 250);

                                
                                }}
                            />)} title="Change Color" trigger="click" style={{ fontFamily: 'Roboto, sans-serif', }}>
    
                                <div style={{ height: 20, width: '70%', backgroundColor: reference.color, borderRadius: 6 }} />
                            </Popover>
                            : <Spin />}
                    </Col>
                    <Col span={6} >
                        Show: 
                        <Checkbox
                            checked={reference.show}
                            onChange={(e) => {
                                reference.show = e.target.checked;
                                this.props.updateReference(reference);
                                this.setState({ reference })
    
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Col>
                    <Col span={6} >
                        Dash: 
                        <Checkbox
                            checked={reference.dash}
                            onChange={(e) => {
                                reference.dash = e.target.checked;
                                this.props.updateReference(reference);
                                this.setState({ reference })
    
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Col>
                    <Col span={4} >
                        Location: 
                        <ButtonGroup aria-label="outlined primary button group" size="small">


                        {[
                            { key: "left", label: "Left" },
                            { key: "right", label: "Right" },
                        ].map((b) => {

                            return (
                                <Button key={b.key}
                                    onClick={() => {
                                        reference.location = b.key;
                                        this.props.updateReference(reference);
                                        this.setState({ reference })
                                    }} variant={reference && reference.location === b.key ? "contained" : "outlined"} color="primary">{b.label}</Button>
                            )
                        })}
                    </ButtonGroup>
                    </Col>
                    <Col span={4} >
                        {reference.save && (
                            <SaveReference />
                        )}
                        
                    </Col>
                    <Col span={4} style={{textAlign: 'right'}}>
                        <DeleteReference keyTag={reference.key} />
                    </Col>
                    <Col span={24} >
                       <hr />
                    </Col>
                </Row>
    
            )
        }
        else {
            return null;
        }
        
    }

}