import React from 'react';
import { Row, Col, Card, Rate, Pagination, Button, Avatar, Input, Table, Tag } from 'antd';
import { fire } from '../../../fire';import { Link } from 'react-router-dom';
import moment from 'moment';

 export default class AccountHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountInfo: this.props.accountInfo,
            user: this.props.user,
            rentalHistory: [],
          };
      }

      

    componentDidMount() {
        this.removeAuthListener = fire.auth().onAuthStateChanged(user => {

            const db = fire.firestore();

            window.scrollTo(0, 0);
            db.collection(`users`)
            .doc(user.uid)
            .collection("rentalHistory")
            .onSnapshot((snapshot) => {
                const rentalHistory = [];
                snapshot.forEach((doc) => {
                    rentalHistory.push({ ...doc.data() })
                })
              
                this.setState({ rentalHistory, user })
            });
        });
    }

      render () {


        const columns = [
            {
                title: 'Thumb',
                dataIndex: ['puzzle','imgLink'],
                width: '100px',
                key: 'imgLink',
                render: (imgLink, row) => {
                    const key = row.puzzle.key;
                    return (
                       <Link style={{ textDecoration: 'none', cursor: 'pointer', }} to="/puzzle" onClick={()=>{
                        
                        const currentKeyRef = fire.database().ref(`users/${this.state.user.uid}/currentPuzzle/key`);
                        currentKeyRef.set(key);
                       }}>
                        <img src={imgLink} height={'45px'} />
                    </Link>
                    )
                }
            },
            {
                title: 'Name',
                dataIndex: ['puzzle','name'],
                key: 'name',

            },
            {
                title: 'Pieces',
                dataIndex: ['puzzle','pieces'],

            },
          
            
            {
                title: 'Date',
                dataIndex: 'renteeDate',
                render: (t) => {
                    return (moment(t, 'X').format('MM-DD-YYYY'))
                }

            },

           
            
        ];


        const InputHeader = (props) => {
            return (

                <Row gutter={[32,]} align="middle" >
                    <Col span={12} style={{ fontSize: 16  }}>
                        <b>{props.name}</b>
                    </Col>
                    
                </Row>

            )

        }


        const { accountInfo, rentalHistory,  } = this.state;
          return (
              <div>
                  <Card style={{boxShadow: '3px 3px 8px #888888',}}>
                  <Row gutter={[32, 32]} justify="space-around" >
                      <Col span={24} style={{ fontSize: 24 }}>
                          Check Out History
                      </Col>
                      
                  </Row>

                 
                      
                      <Row gutter={[32,]} align="middle" style={{ paddingTop: 20 }}>


                      <Col span={24} style={{ fontSize: 36 }}>
                          <Table columns={columns} dataSource={Object.values(rentalHistory)}
                          />
  
                      </Col>
  
                  </Row>

                 
                  </Card>
              </div>
          )
      }

 }