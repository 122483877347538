import { Button } from '@material-ui/core';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Col, Input, Result, Row, message, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import { fire } from '../../../fire';

import './common.css';


const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'gray',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: '#87BBFD',
            },
        },
        invalid: {
            iconColor: '#FFC7EE',
            color: '#FFC7EE',
        },
    },
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
    disabled
}) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
        <Input style={{
            fontFamily: 'Raleway, sans-serif', fontSize: 18, borderRadius: 4,
            border: '2px solid #d8d8d8', height: 50
        }}
            id={id}
            disabled={disabled}
            size="large"
            placeholder={label}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        />
    </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
    <button style={{
        color: "#fff",
        height: '40px',
        color: 'rgba(0,0,0,.8)',
        width: 200,
        height: 50,
        padding: '6.4px 20px',
        fontSize: '18px',
        borderRadius: '40px',
        fontFamily: 'Raleway, sans-serif',
        border: '2px solid #1890ff',
        background: 'white',
    }}
        className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? 'Processing...' : children}
    </button>
);

const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#FFF"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

const DEFAULT_STATE = {
    error: null,
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    email: '',
    phone: '',
    name: '',

};

const ReturnButton = (props) => {
    let history = useHistory();
    const email = props.email;
    const password = props.password;
    return (

        <Button color="primary" variant="contained" size="large"

            onClick={() => {
                fire.auth().signInWithEmailAndPassword(email, password).then((result) => {
                    this.checkedCreds();
                    history.push('/')
                }).catch((error) => {
                    // Handle Errors here.
                 
                    this.checkedCreds();
                    message.error("Not a valid email or password");
                    // ...
                });
            }} >

            Go to Account</Button>


    );
}

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            DEFAULT_STATE: DEFAULT_STATE,
            paying: false,
            success: false,
            info: this.props.info,
            loading: false,
        };
    }

    componentDidUpdate(prevProps) {



        if (this.props.info !== prevProps.info) {

            this.setState({ info: this.props.info, loading: false });
        }

    }

    componentDidMount() {
        this.setState({ email: this.props.info.email, name: `${this.props.info.firstName} ${this.props.info.lastName}` });
    }


    reset = () => {
        this.setState(DEFAULT_STATE);
    };

    render() {
        const { error, processing, paymentMethod, name, email, phone, paying, success, info, userInfo, plan, loading, user } = this.state;
        const { stripe } = this.props;


        const BackButton = () => {
            return (
                <Button variant="contained" color="secondary" style={{textTransform: 'capitalize'}}  onClick={() => {

                    info.status = "userInfo";
                    this.props.updateInfo(info)
                    this.setState({ info })
                }}>Go Back</Button>
            )
        }


        const SignButton = ({ processing, error, children, disabled }) => {

            const history = useHistory();
            return (
                <Button variant="contained" color="primary" style={{textTransform: 'capitalize'}} onClick={async () => {
                    this.setState({ paying: true })
                    const { stripe, elements } = this.props;
                    const { phone, name, error, cardComplete, info } = this.state;

                    const email = info.email;

                    if (email.includes("@") && email.includes(".")) {
                        if (!stripe || !elements) {
                            // Stripe.js has not loaded yet. Make sure to disable
                            // form submission until Stripe.js has loaded.
                            return;
                        }

                        // Get a reference to a mounted CardElement. Elements knows how
                        // to find your CardElement because there can only ever be one of
                        // each type of element.
                        const cardElement = elements.getElement(CardElement);

                        // Use your card Element with other Stripe.js APIs

                        
                     

                        const { err, paymentMethod, } = await stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                            billing_details: { name, email },
                        })


                        if (err || paymentMethod === undefined) {
                            this.props.openSnack("error", "Double check payment information")
                            this.setState({ paying: false })
                        } else {








                            const proxyUrl = "https://cors-anywhere.herokuapp.com/";
                            const urlCustomer = "https://us-central1-doprofile-fe8ca.cloudfunctions.net/test/createCustomer";
                            const resCustomer = await fetch(urlCustomer, {
                                method: 'POST',
                                body: JSON.stringify({
                                    email: email,
                                    payment_method: paymentMethod.id,
                                    invoice_settings: {
                                        default_payment_method: paymentMethod.id,
                                    },
                                }),
                            });

                            // The customer has been created

                            const customer = await resCustomer.json();
                            customer.body = JSON.parse(customer.body);


                            if (customer.body.createCustomer === undefined) {
                                this.props.openSnack("error", "Double check payment information")
                                console.log(customer.body)
                                this.setState({ paying: false })
                            }
                            else {



                                const c = customer.body.createCustomer;
                             






                                const customer_ID = customer.body.createCustomer.id;


                                const urlSubscription = "https://us-central1-doprofile-fe8ca.cloudfunctions.net/test/createSubscription";
                                const subscription = await fetch(urlSubscription, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        customer: customer_ID,
                                        items: [{ price: "price_1IPBRrHU6eb6yd8ur54T1eKv" }],

                                    }),
                                });

                                const sub = await subscription.json();
                                sub.body = JSON.parse(sub.body);


                                const p = sub.body.createSubscription;
                               

                                if (sub.body.createSubscription === undefined) {
                                    this.props.openSnack("error", "Double check payment information")
                                    console.log(sub.body)
                                    this.setState({ paying: false })
                                }
                                else {



                                this.setState({ paying: false, success: true });



                                const password = info.password;
                                const displayName = info.displayName;
                                fire.auth().createUserWithEmailAndPassword(email, password).then((result) => {

                                    var user = fire.auth().currentUser;
                                    user.updateProfile({
                                        displayName: displayName,
                                    })
                                    const currentCompany = uuid();

                                    const db = fire.firestore();
                                    db.collection("users")
                                        .doc(user.uid)
                                        .set({
                                            id: user.uid, displayName: displayName, email: user.email.toLowerCase(), companies: [currentCompany], adminLevel: "admin",
                                            firstName: info.firstName, lastName: info.lastName, company: info.company, currentAccount: "none", accounts: [], currentCompany, initialCompany: currentCompany, currentProfile: "none"
                                        })

                                        .then(() => {


                                            db.collection("users")
                                                .doc(user.uid)
                                                .collection("accountInfo")
                                                .doc("payment")
                                                .set({ ...paymentMethod, label: "payment" });


                                        })
                                        .then(() => {





                                            db.collection("users")
                                                .doc(user.uid)
                                                .collection("accountInfo")
                                                .doc("subscription")
                                                .set({ ...sub.body.createSubscription, label: "subscription", });
                                        })
                                        .then(() => {


                                            db.collection("users")
                                                .doc(user.uid)
                                                .collection("accountInfo")
                                                .doc("customer")
                                                .set({ ...c, label: "customer" });
                                        })


                                        .then(() => {
                                            history.push('/')
                                        })






                                }).catch((error) => {
                                    // Handle Errors here.

                                    var errorCode = error.code;
                                    
                                    var errorMessage = error.message;
                                    if (errorCode === "auth/email-already-in-use") {
                                        message.error("Email already in use.");
                                        info.agreeTerms = false;
                                        this.setState({ info })
                                    }
                                    else {
                                        message.error("Unidentified error.  Please refresh page and try again.")
                                    }

                                    // ...
                                });
                            }
                        }





                        }
                    }
                    else {
                        this.props.openSnack("error", "Check Email")
                    }




                }}>Sign up! $6.50/per month</Button>
            )
        }

        return (
            <div >
                <Row style={{ minHeight: '100vh', display: !loading ? 'none' : null }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        Loading <Spin />
                    </Col>
                </Row>
                <div style={{ minHeight: '100vh', display: loading ? 'none' : null }}>

                    {success ? <Row justify="space-around" align="middle" gutter={[32, 32]}>
                        <Col span={16} style={{ textAlign: 'center' }}>
                            <Result
                                status="success"
                                title="Subscription Order Success!"
                                subTitle="A reciept will be emailed to you."

                            />
                        </Col>
                      



                    </Row> :
                        <Row justify="space-around">
                            <Col xs={24} sm={14}>
                                <form className="Form" onSubmit={this.handleSubmit} >
                                    <fieldset className="FormGroup" style={{ display: paying ? 'none' : null }}>
                                        <Row justify="space-around" gutter={[32, 32]}>

                                            <Col xs={24}>
                                                <Field
                                                    label="Name"
                                                    id="name"
                                                    type="text"
                                                    placeholder="Jane Doe"
                                                    required
                                                    autoComplete="name"
                                                    value={name}
                                                    onChange={(event) => {
                                                        this.setState({ name: event.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={24}>
                                                <Field
                                                    disabled={true}
                                                    label="Email"
                                                    id="email"
                                                    type="email"
                                                    placeholder="janedoe@gmail.com"
                                                    required
                                                    autoComplete="email"
                                                    value={info.email}
                                                    onChange={(event) => {
                                                        this.setState({ email: event.target.value });
                                                    }}
                                                />
                                            </Col>
                                            
                                        </Row>
                                    </fieldset>

                                    <Row justify="space-around" gutter={[32, 32]}>
                                        <Col xs={24} style={{ textAlign: 'left' }}>
                                            <fieldset className="FormGroup" style={{ display: paying ? 'none' : null, paddingTop: 0 }}>
                                                <p style={{}}>Card Information</p>
                                                <CardField
                                                    onChange={(event) => {
                                                        this.setState({
                                                            error: event.error,
                                                            cardComplete: event.complete,
                                                        });
                                                    }}
                                                />
                                            </fieldset>
                                        </Col>


                                        <Col xs={24} style={{ textAlign: 'center' }}>
                                            {error && <ErrorMessage>{error.message}</ErrorMessage>}
                                            {paying ? <span>Processing Payment...<Spin /></span> : null}
                                        </Col>

                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            {paying ? null : <SignButton />}

                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            {paying ? null : <BackButton info={info} />}

                                        </Col>

                                    </Row>


                                </form></Col></Row>}




                </div>
            </div>

        );
    }
}

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} plan={props.plan} user={props.user} info={props.info} updateInfo={props.updateInfo} openSnack={props.openSnack} />
        )}
    </ElementsConsumer>
);

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export default class Checkout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paying: false,
            success: false,
            stripePromise: loadStripe("pk_live_51IP9lNHU6eb6yd8udT2j7ayZaeIFN0QhtHxO3maaenbLgftUhkHEEEmmazHeDpUYO2XYlgm4CUmdsBElhcBxiX8V000635kMpK"),
            plan: this.props.plan,
            info: this.props.info,
            widthSize: this.props.widthSize,
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.info !== prevProps.info) {

            this.setState({ info: this.props.info });
        }
        if (this.props.widthSize !== prevProps.widthSize) {

            this.setState({ widthSize: this.props.widthSize });
        }

    }

    componentDidMount() {
       

    }



    render() {


        const { paying, success, info, stripePromise, widthSize } = this.state;
        return (
            <div style={{ paddingTop: widthSize === "xs" ? 60 : null }}>

                {paying ? <Spin /> :
                    <Elements stripe={stripePromise}>
                        <InjectedCheckoutForm info={info} updateInfo={this.props.updateInfo} widthSize={widthSize} openSnack={this.props.openSnack} />
                    </Elements>}

                {success ? "Nice Job!" : null}


            </div>


        )
    }

}