import { Button, Checkbox, List, TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SaveIcon from '@material-ui/icons/Save';
import { Card, Col, Modal, Popover, Row, Slider, Spin } from 'antd';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { CompactPicker } from 'react-color';
import uuid from 'react-uuid';
import {
    CartesianGrid, ComposedChart,
    Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Label
} from 'recharts';
import { fire } from '../../../fire';
import ProfileReference from './profileReference';
import ProfileSettings from './profileSettings';
import { saveAs } from 'file-saver';
import ParameterReference from './parameterReference';

var randomColor = require('randomcolor');

const homePageStyle = (theme) => ({
    root: {
        width: "300px"
    },
    selected: {
        backgroundColor: "#3f51b5c2 !important",
        color: "white",
        fontWeight: 600
    },
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    }
});



class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.userInfo,
            profile: this.props.profile,
            changingProfile: false,
            showProfile: false,
            yAxisData: [],
            referenceModal: false,
            graphData: {},
            changingColor: false,
            changingProfile: false,
            newColor: false,
            references: {}


        };
    };



    componentDidUpdate(prevProps) {

        if (this.props.profile !== prevProps.profile) {
            const profile = this.props.profile;
            console.log(profile);


            this.setState({ profile: this.props.profile, userInfo: this.props.userInfo, references: profile.references })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo, })
        }


    }

    componentDidMount() {
        console.log(this.state.profile);
        window.scrollTo(0, 0);

        const yAxisData = Object.values(this.state.profile.graphData).filter((f) => f.type === "depth"
        )


        setTimeout(() => {
            this.setState({ showProfile: true, yAxisData, profile: this.state.profile, references: this.props.profile.references });
        }, 250);
    }



    downloadDom = () => {
        const { widget } = this.state;


    }

    viewReferences = () => {
        this.setState({ referenceModal: true })
    }

    closeModal = () => {
        this.setState({ referenceModal: false })
    }

    updateGraphData = (x, y, z) => {

        const graphData = this.state.graphData;
        graphData[x][y] = z;

        this.props.updateProfile("graphData", graphData)
        setTimeout(() => {
            this.setState({ graphData, newColor: false, changingProfile: false })
        }, 100);

    }
    updateProfile = (x, y) => {
        const profile = this.state.profile;
        profile[x] = y;

        setTimeout(() => {
            this.setState({ profile, newColor: false, changingProfile: false })
        }, 100);

    }
    updateYAxis = (x, y) => {
        const yAxisData = this.state.yAxisData;
        yAxisData[0][x] = y;

        setTimeout(() => {
            this.setState({ yAxisData, newColor: false, changingProfile: false })
        }, 100);

    }

    downloadDom = () => {
        const { widget } = this.state;
        htmlToImage.toBlob(document.getElementById(`profile`))
            .then(function (blob) {
                window.saveAs(blob, 'Depth Profile.png');
            })
            .then(() => {
                this.setState({ downloadingImage: false })
            })

    }

    updateReference = (reference) => {

        const { profile } = this.state;
        this.setState({ changingProfile: true })
        profile.references[reference.key] = reference;


        setTimeout(() => {
            this.setState({ profile, changingProfile: false })
        }, 250);
    }

    deleteReference = (reference) => {

        const { profile } = this.state;
        this.setState({ changingProfile: true })
        delete profile.references[reference.key];

        setTimeout(() => {
            this.setState({ profile, changingProfile: false })
        }, 250);
    }

    updateParameter = (parameter) => {

        const { profile } = this.state;
        this.setState({ changingProfile: true })
        profile.graphData[parameter.key] = parameter;


        setTimeout(() => {
            this.setState({ profile, changingProfile: false })
        }, 250);
    }





    render() {

        const { profile, changingProfile, showProfile, yAxisData, referenceModal, graphData, changingColor, newColor, userInfo, references } = this.state;
        const { classes } = this.props;



        const boxShadow = 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px';


        const AddReferenceButton = () => {

            return (
                <Button variant="contained" color="primary" size="small"
                    onClick={() => {
                        this.setState({ changingProfile: true })
                        const key = uuid();
                        profile.references[key] = {
                            yValue: 1,
                            color: randomColor(),
                            dash: true,
                            show: true,
                            label: "",
                            key,
                            location: 'left',
                            save: false,
                            unix: moment().format('X')
                        };
                        setTimeout(() => {
                            this.setState({ profile, changingProfile: false })
                        }, 250);


                    }}>
                    Add +
                </Button>
            )
        }







        const renderLegend = (props) => {
            const { payload } = props;

            return (
                <Col offset={2} span={22}>
                    <Row gutter={[8, 8]} align="middle" justify="center" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14, }}>

                        {
                            payload.map((e, index) => {


                                return (
                                    <Col span={parseFloat(profile.width) > 80 ? 6 : parseFloat(profile.width) > 50 ? 8 : parseFloat(profile.width) > 0 ? 12 : 12} style={{ textAlign: 'center' }}>

                                        <p style={{ color: e.color, fontFamily: 'Roboto, sans-serif' }}><FiberManualRecordIcon style={{ fontSize: 12 }} />
                                            <b style={{ cursor: "pointer" }}
                                            >{e.payload.label}</b>
                                        </p>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Col>
            );
        }


        const CustomTooltip = ({ active, payload }) => {
            if (active && payload !== null) {
                return (
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: 3, padding: 10 }}>
                        <b style={{ fontSize: 16 }}>{payload !== undefined && payload[0] !== undefined ? `Depth - ${parseFloat(payload[0].payload[yAxisData[0].label]).toFixed(2)} (${yAxisData[0].units}) ` : null} </b>
                        {payload !== undefined ? payload.map((p) => {


                            return (
                                <p style={{ color: p.stroke || p.fill }}>{p.name}: {parseFloat(p.value).toFixed(2)}</p>
                            )
                        }) : null}
                    </div>
                );
            }
            else {
                return null;
            }
        }



        if (!showProfile) {
            return null;
        }
        else {
            return (
                <Row justify="center" style={{ width: '100%' }} gutter={[4, 16]}>

                    <Col xs={24} sm={10} style={{ textAlign: 'left' }}>
                        <Card style={{ boxShadow, }}>

                            <Row style={{ width: '100%' }} gutter={[4, 16]}>



                                <Col xs={24} sm={12} style={{ textAlign: 'right', }}>
                                    <Button variant="contained" size="large" color="primary" fullWidth
                                        onClick={() => {
                                            this.setState({ downloadingImage: true })
                                            this.downloadDom()
                                        }}>
                                        Snapshot  <CropOriginalIcon />
                                    </Button>
                                </Col>
                                <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                                    <Button variant="outlined" size="large" color="primary" fullWidth
                                        onClick={() => {

                                            const db = fire.firestore();
                                            const key = profile.key || uuid();
                                            profile.key = key;
                                            profile.lastSaved = moment().format('X');
                                            db.collection("users")
                                                .doc(userInfo.id)
                                                .collection("profiles")
                                                .doc(key)
                                                .set(profile)
                                                .then(() => {
                                                    this.props.openSnack("success", "Profile Saved!")
                                                })
                                        }}>
                                        Save   <SaveIcon />
                                    </Button>
                                </Col>





                            </Row>


                            <Col span={24} style={{ fontSize: 18 }}>
                                <b>Profile</b>
                            </Col>
                            <Col span={24} >
                                <hr style={{ borderTop: '2px solid' }} />
                            </Col>

                            <Col span={24} style={{ fontSize: 12 }}>
                                <TextField
                                    value={profile.name}
                                    size="small"
                                    fullWidth
                                    style={{ fontSize: 12 }}
                                    id="outlined-number"
                                    label="Profile Name"
                                    onChange={(e) => {

                                        profile.name = e.target.value;
                                        this.setState({ profile })

                                    }}

                                    variant="outlined"
                                />

                            </Col>


                            <Row style={{ paddingTop: 10 }}>
                                <Col span={4}>
                                    Width:
                                    </Col>
                                <Col xs={20}>
                                    <Slider
                                        defaultValue={parseFloat(profile.width)}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        onChange={(profileWidth) => {

                                            const width = `${profileWidth}%`
                                            this.setState({ profile, changingProfile: true })
                                            this.updateProfile("width", width)



                                        }}
                                        step={10}
                                        marks
                                        min={10}
                                        max={100}
                                    />
                                </Col>
                                <Col xs={24} sm={4} style={{ fontSize: 14, }} >
                                    Height:
                                 </Col>
                                <Col xs={24} sm={20}>
                                    <Slider
                                        defaultValue={profile.height}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        onChange={(profileHeight,) => {
                                            this.setState({ profile, changingProfile: true })
                                            this.updateProfile("height", profileHeight)
                                        }}
                                        step={100}
                                        marks
                                        min={100}
                                        max={1000}
                                    />
                                </Col>

                                <Col span={6}>
                                    Show Surface
                                </Col>
                                <Col span={14}>
                                    <Checkbox
                                        checked={profile.surface}
                                        onChange={(e) => {
                                            this.setState({ changingProfile: true })
                                            this.updateProfile("surface", e.target.checked)
                                        }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Col>

                            </Row>


                            <Col span={24}>
                                <hr />
                            </Col>


                            <Col span={24}>

                                <Row align="middle">
                                    <Col span={24} style={{ fontSize: 18 }}>
                                        <b>Y-Axis</b>
                                    </Col>
                                    <Col span={24} >
                                        <hr style={{ borderTop: '2px solid' }} />
                                    </Col>
                                    <Col span={8}>
                                        Y-Axis
                                    </Col>
                                    <Col span={12}>
                                        Domain
                                    </Col>

                                    <Col span={8}>
                                        {yAxisData[0].label}
                                    </Col>
                                    {yAxisData[0] && (
                                        <Col span={12}>
                                            <Row align="middle">



                                                {!yAxisData[0].domainAuto && yAxisData[0] && (
                                                    <Col span={10} style={{ textAlign: 'left' }}>
                                                        <TextField
                                                            value={yAxisData[0].min}
                                                            size="small"
                                                            id="outlined-number"
                                                            label="Min"
                                                            type="number"
                                                            onChange={(e) => {

                                                                this.setState({ changingProfile: true })
                                                                this.updateYAxis("min", parseFloat(e.target.value));

                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Col>
                                                )}


                                                {!yAxisData[0].domainAuto && (
                                                    <Col span={10} style={{ textAlign: 'left' }}>
                                                        <TextField
                                                            value={yAxisData[0].max}
                                                            size="small"
                                                            onChange={(e) => {
                                                                this.setState({ changingProfile: true })
                                                                this.updateYAxis("max", parseFloat(e.target.value));
                                                            }}
                                                            id="outlined-number"
                                                            label="Max"
                                                            type="number"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Col>
                                                )}

                                                <Col span={4} style={{ textAlign: 'left' }}>
                                                    <Checkbox
                                                        checked={yAxisData[0].domainAuto}
                                                        onChange={(e) => {
                                                            this.setState({ changingProfile: true })
                                                            this.updateYAxis("domainAuto", e.target.checked);
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                            </Row>



                                        </Col>
                                    )}

                                </Row>




                            </Col>

                            <Col span={24}>
                                <hr />
                            </Col>



                            <Row>
                                <Col span={24} style={{ fontSize: 18 }}>
                                    <b>Parameters</b>
                                </Col>
                                <Col span={24} >
                                    <hr style={{ borderTop: '2px solid' }} />
                                </Col>

                            </Row>




                            {Object.values(profile.graphData).length > 0 && (<List classes={{ root: classes.root }} >
                                {Object.values(profile.graphData).map((g) => {




                                    if (g.type !== "depth") {
                                        return (
                                            <Col span={24}>

                                                <ParameterReference parameter={g} updateParameter={this.updateParameter} profile={profile} />


                                            </Col>


                                        )
                                    }


                                })}
                            </List>)}


                            <Row>
                                <Col span={20} style={{ fontSize: 18 }}>
                                    <b>References</b>
                                </Col>
                                <Col span={4}>
                                    <AddReferenceButton />
                                </Col>


                                <Col span={24}>
                                    <hr style={{ borderTop: '2px solid' }} />
                                </Col>
                            </Row>

                            <Row style={{ paddingTop: 7 }}>

                                {Object.values(profile.references).map((r) => {



                                    return (
                                        <Col span={24}>
                                            <ProfileReference reference={r} updateReference={this.updateReference} deleteReference={this.deleteReference} />

                                        </Col>
                                    )


                                })}
                            </Row>



                        </Card>

                    </Col>







                    <Col xs={24} sm={14}>
                        <Row align="middle" gutter={[8, 8]} justify="center" >






                            <div style={{ width: profile.width, height: '100%', }} id="profile">
                                <div style={{ width: '100%', height: profile.height, backgroundColor: 'white' }}>
                                    {!changingColor && (
                                        <ResponsiveContainer >
                                            <ComposedChart

                                                layout="vertical"
                                                data={profile.data}
                                                margin={{
                                                    top: 20, right: 20, bottom: 20, left: 20,
                                                }}
                                            >


                                                <CartesianGrid strokeDasharray="6 6" />



                                                <XAxis domain={[0, 10]} reversed={false} hide={true} />

                                                {Object.values(profile.graphData).map((d) => {
                                                    if (d.type !== "depth" && d.disable === false) {
                                                        return (
                                                            <XAxis domain={d.domainAuto ? ['auto', 'auto'] : [d.min, d.max]} reversed={false} hide={d.hideAxis}
                                                                orientation={d.orientation}
                                                                type="number" xAxisId={d.label} style={{ stroke: d.colorAxis ? d.color : null, fontSize: 15, }} />
                                                        )
                                                    }


                                                })}
                                                <YAxis domain={yAxisData[0].domainAuto ? ['auto', 'auto'] : [yAxisData[0].min, yAxisData[0].max]} dataKey={yAxisData[0].label}>
                                                    <Label style={{ textAnchor: 'middle' }}
                                                        value={yAxisData[0].newLabel}
                                                        position='insideLeft'
                                                        angle={-90} />
                                                </YAxis>

                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend content={renderLegend} />



                                                {Object.values(profile.graphData).map((d) => {
                                                    if (d.type !== "depth" && d.disable === false) {
                                                        return (
                                                            <Line type="monotone" dataKey={d.label}
                                                                label={d.customLabel ? d.customLabelText.length > 0 ? d.customLabelText : d.label : d.newLabel}

                                                                name={d.customLabel ? d.customLabelText.length > 0 ? d.customLabelText : d.label : d.newLabel}
                                                                dot={d.dots ? { stroke: d.color, strokeWidth: 1.5 } : false}
                                                                stroke={d.color} xAxisId={d.label} strokeWidth={2} />
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }


                                                })}


                                                {Object.values(profile.references).map((f) => {

                                                    if (f.show === true) {
                                                        return (
                                                            <ReferenceLine y={f.yValue || 0} stroke={f.color.toLowerCase()}
                                                                strokeDasharray={f.dash && f.dash === true ? "4 4" : "0 0"}
                                                                strokeWidth={2} label={{
                                                                    value: f.label, position: f.location === "right" ? "insideBottomRight" : "insideBottomLeft", fontFamily: 'Roboto, sans-serif',
                                                                    fontWeight: 'bold', offset: 3
                                                                }} isFront={true} style={{ zIndex: 3 }} />
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }




                                                })}
                                                <ReferenceLine y={0} yAxis={this.xaxis} label={{ position: 'bottom', value: profile.surface ? 'Surface' : '' , }} stroke="#2180c3" strokeDasharray="8 8" strokeWidth={profile.surface ? 4 : 0} />

                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    )}



                                </div>
                            </div>


                            <Modal
                                footer={(null)}
                                width={'70%'}
                                visible={referenceModal}
                                onCancel={this.closeModal}
                            >
                                {referenceModal && (<ProfileSettings closeModal={this.closeModal} updateSettings={this.updateSettings} updateGraphData={this.updateGraphData} profile={profile} />)}
                            </Modal>


                        </Row>
                    </Col>

                </Row>





            );
        }

    }
}

export default withStyles(homePageStyle)(Profile); 
